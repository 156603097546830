import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { DrawerTableFilters } from 'common/components/DrawerTableFilters'
import { Page } from 'common/components/Page'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { Download } from './download'
import { Filters } from './filters'

export function CostCodeOptions() {
  const history = useHistory()
  const location = useLocation()

  const persistentFilters = usePersistentFilters()
  const activeKey = new URLSearchParams(location.search).get('filter_tab')

  const tabItems = [
    { label: 'Filters', key: 'filters', children: <Filters /> },
    { label: 'Download', key: 'download', children: <Download /> },
  ]

  return (
    <DrawerTableFilters
      title="Cost Code Options"
      useAlgolia={false}
      appliedFiltersCounter={persistentFilters.appliedFiltersCount}
      padding={0}
    >
      <Page>
        <Page.Tabs
          activeKey={activeKey || 'filters'}
          onChange={(key) => {
            const searchParams = new URLSearchParams(history.location.search)
            searchParams.set('filter_tab', key)
            history.push({ search: searchParams.toString() })
          }}
          items={tabItems}
        />
      </Page>
    </DrawerTableFilters>
  )
}
