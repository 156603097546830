import React, { useEffect, useState } from 'react'

import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'

import _ from 'lodash'
import moment from 'moment'

import { CheckOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import ArrowLeftIcon from '@ant-design/icons/ArrowLeftOutlined'
import { Alert, Button, Col, message, Modal, PageHeader, Popconfirm, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { OrderBlockedHeader } from 'common/components/OrderBlockedHeader'
import { OrderBlockedModals } from 'common/components/OrderBlockedModals'
import { Page } from 'common/components/Page'
import OrderState from 'common/components/statuses/order_state'
import UnloadWarning from 'common/components/unload_warning'
import { Visibility } from 'common/components/Visibility'
import { formatDateString } from 'common/helpers/formatters'
import { getOrderTitleType } from 'common/helpers/get_order_type_title'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { useNotifyOrder } from 'common/hooks/use-notify-order'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { useQuery } from 'common/hooks/use-query'
import { Events, trackEvent } from 'common/pendo/event_tracking'
import { OrderStates, OrderSubStates, QuoteExtractionStates } from 'common/server/server_types'

import { InternalComments } from 'contractor/components/InternalComments'
import { TCMSyncStatusDetailed } from 'contractor/components/ManualSyncStatusDetailed'
import { FoundationSyncStatusDetailed } from 'contractor/components/ManualSyncStatusDetailed/foundation_sync_status_detailed'
import { useFlag } from 'contractor/hooks/use-flag'
import { useLockPo } from 'contractor/hooks/use-lock-po'
import { useStores } from 'contractor/hooks/use-stores'
import { SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY } from 'contractor/pages/NewQuote/context'
import { IntegrationSync } from 'contractor/pages/OrderDetail/integration_sync'
import { LockPo } from 'contractor/pages/OrderDetail/lock_po'
import { QuoteAlert } from 'contractor/pages/OrderDetail/QuoteReconciliation/quote_alert'
import { QuoteReconciliationDrawer } from 'contractor/pages/OrderDetail/QuoteReconciliation/quote_reconciliation_drawer'
import { WithdrawCancelation } from 'contractor/pages/OrderDetail/withdraw_cancelation'

import { OrderActions } from './components/Actions'
import { History } from './components/History'
import { Invoices } from './components/Invoices'
import { MaterialsAndDeliveries } from './components/MaterialAndDeliveries'
import { OrderInfo } from './components/OrderInfo'
import { Tabs, TabAttachments, TabVendorChat } from './components/Tabs'
import { Total } from './components/Total'

const { confirm, destroyAll } = Modal

const QuoteValidUntil = () => {
  const { orderStore } = useStores()

  const { state, quote_scheduled_to_expire_at } = orderStore.selectedOrder

  if (state === OrderStates.QUOTED && !!quote_scheduled_to_expire_at) {
    return (
      <Typography.Text type="secondary" style={{ fontSize: 14, marginRight: 8 }}>{`Valid Until: ${formatDateString(
        quote_scheduled_to_expire_at,
      )}`}</Typography.Text>
    )
  }

  return null
}

const statesToShowIntegration = [
  OrderStates.ORDERED,
  OrderStates.PARTIALLY_SHIPPED,
  OrderStates.SHIPPED,
  OrderStates.DELIVERED,
]

const QuickPOAlert = (props) => (
  <Alert message="This PO was Quick Created during Invoice Approval" type="info" {...props} />
)

export const OrderDetailPage = observer(() => {
  const {
    orderStore,
    userStore,
    uploaderStore,
    companySettingStore,
    unitsStore,
    costCodeStore,
    companyMaterialStore,
    addressStore,
    integrationStore,
    projectStore,
    quoteStore,
    deliveryStore,
  } = useStores()
  const history = useHistory()
  const { push } = usePushToOrder()
  const [actionClicked, setActionClicked] = useState(-1)

  const [isSubmitting, setSubmitting] = useState(false)
  const [isSpreadsheetMode, setSpreadsheetMode] = useState(false)

  const quoteFullyMatched = orderStore?.selectedOrder?.quote_extraction?.quote_fully_matched
  const showQuoteAlert =
    useFlag('rfq_extraction') &&
    orderStore?.selectedOrder?.state === OrderStates.QUOTED &&
    orderStore?.selectedOrder?.quote?.url &&
    quoteStore?.quote?.status !== QuoteExtractionStates.EXTRACTING
  const [showQuoteDrawer, setShowQuoteDrawer] = useState(false)

  const [purchaseOrderModalVisible, setPurchaseOrderModalVisible] = useState(false)
  const [syncOrderWithIntegration, setSyncOrderWithIntegration] = useState(
    localStorage.getItem(SYNC_ORDER_WITH_INTEGRATION_LOCAL_KEY) === 'true',
  )

  const location = useLocation()
  const { params } = useRouteMatch()

  const { notifyOrder } = useNotifyOrder({ orderId: orderStore.selectedOrder?.id, operation: 'update' })

  const isMobileScreen = useMediaQuery('md')
  const tcmCsvV2DownloadEnabled = useFlag('tcm_csv_v2_download')
  const foundationCsvDownloadEnabled = useFlag('foundation_csv_download')
  const { orderLocked: isPoLocked, orderSession } = useLockPo(orderStore?.selectedOrder)
  const isRFQ = orderStore?.selectedOrder?.state === OrderStates.QUOTED

  const showLockIcon = isPoLocked || userStore.cannotSendAndUpdateOrders

  const showOrderConfirmOverride =
    userStore.canInputVendorData && orderStore.selectedOrder?.sub_state === OrderSubStates.ORDERED_REQUESTED

  const { isLoading: isLoadingOrder } = useQuery(() => {
    return Promise.all([
      orderStore.orderSubscribe(params['id'], userStore.currentUser?.id),
      orderStore.selectOrder(params['id']),
    ])
  })

  useEffect(() => {
    return () => {
      orderStore.clearOrder()
    }
  }, [])

  // Until we have company_attributes populated just return null
  const { isLoading } = useQuery(() => {
    return Promise.all([
      companyMaterialStore.loadSearchKey(),
      companySettingStore.indexCompanyMaterialConfiguration(),
      unitsStore.maybeUnits(),
      costCodeStore.fetchAllActiveCostCodes(),
      costCodeStore.fetchAllActivePhaseCodes(),
      companySettingStore.maybeIndexUsers(),
      addressStore.maybeIndexAddresses(),
      orderStore.getOrderSettings(), // For Tags
      projectStore.maybeIndexProjects(),
      companySettingStore.indexOtherSettings(),
    ])
  })
  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })

  useQuery(() => {
    return quoteStore.getQuote(orderStore.selectedOrder?.id, orderStore.selectedOrder?.quote_extraction?.id)
  }, [orderStore.selectedOrder?.quote_extraction?.id, orderStore?.selectedOrder?.id])

  const requiredOrderFields = [
    ...(companySettingStore.otherSettings?.required_order_fields?.quantity
      ? [
          {
            key: 'quantity',
            label: 'Quantity',
          },
        ]
      : []),
    ...(companySettingStore.otherSettings?.required_order_fields?.unit
      ? [
          {
            key: 'unit',
            label: 'Unit',
          },
        ]
      : []),
    ...(companySettingStore.otherSettings?.required_order_fields?.unit_cost
      ? [
          {
            key: 'unit_cost',
            label: 'Unit Cost',
          },
        ]
      : []),
    ...(companySettingStore.otherSettings?.required_order_fields?.cost_code
      ? [
          {
            key: 'cost_code_id',
            label: 'Cost Code',
          },
        ]
      : []),
  ]

  const maybeAddWatcher = () => {
    const { watcher_ids } = orderStore.selectedOrder
    orderStore.updateSelectedOrder('watcher_ids', _.uniq(watcher_ids.concat([userStore.currentUser?.id])))
  }

  // Hack to redirect to the draft page if the user access the order directly
  const redirectToDraftPage = () => {
    const { id, order_package_id, state, sub_state } = orderStore.selectedOrder
    if ([OrderStates.DRAFT, OrderStates.REQUESTED].includes(state)) {
      push({
        orderId: id,
        orderPackageId: order_package_id,
        state: state,
        subState: sub_state,
      })
    }
  }

  // Hack to redirect to the draft page if the user access the order directly
  useEffect(() => {
    if (orderStore.selectedOrder) {
      redirectToDraftPage()
    }
  }, [orderStore.selectedOrder])

  const goBack = () => {
    orderStore.selectOrder(null)

    if (location.key && location.key !== 'default') {
      return history.goBack()
    }

    history.replace('/orders')
  }

  /*
    The user can switch the project and we should verify if no problem between materials
    and/or cost codes when the company has enabled the configuration to relate both with the projects.
  */
  const validateProject = () => {
    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
    const isProjectFilteringEnabled = costCodeSettings?.project_filtering_enabled
    const isProjectSpecificMaterialEnabled =
      companySettingStore.companyMaterialConfiguration?.company_attributes.includes('project_ids')

    if (!isProjectFilteringEnabled && !isProjectSpecificMaterialEnabled) {
      return []
    }

    const errors = []
    const orderMaterials = orderStore.getPlainOrderMaterials()
    const order = orderStore.selectedOrder
    orderMaterials.forEach((orderMaterial) => {
      const companyMaterial = orderMaterial?.company_material
      if (
        isProjectSpecificMaterialEnabled &&
        Array.isArray(companyMaterial?.project_ids) &&
        companyMaterial?.project_ids?.length &&
        !companyMaterial?.project_ids?.includes(order?.project_id)
      ) {
        errors.push(
          `The project ${order?.project?.name} is not allowed to be related to the material: ${orderMaterial?.company_material?.description}.`,
        )
      }

      const costCode = orderMaterial?.cost_code
      if (
        isProjectFilteringEnabled &&
        costCode?.project_ids.length &&
        !costCode?.project_ids?.includes(order?.project_id)
      ) {
        const canShowPhase =
          costCodeSettings?.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled && !!costCode
        const canShowClass = costCodeSettings?.class_enabled && !!costCode

        const codeLabel = [
          canShowPhase ? costCode?.phase_code || 'N/A' : '',
          costCode?.code,
          canShowClass ? costCode?.clazz || 'N/A' : '',
        ]
          .filter((item) => !!item)
          .join(' / ')
        errors.push(`The project ${order?.project?.name} is not allowed to be related to the cost code: ${codeLabel}.`)
      }
    })

    return errors
  }

  const handleLogError = (error, defaultMessage = '') => {
    if (error?.response?.data?.error) {
      message.error({
        content: error.response.data.error,
        duration: 8,
      })
    } else {
      message.error(defaultMessage || 'Unable to save updates to the order.')
    }
  }

  const saveOrder = async (
    state_changes: {
      ordered_at?: string
      cancelled_at?: string
      cancellation_accepted_at?: string
      cancellation_rejected_at?: string
      cancellation_requested_at?: string
      cancellation_requested_reason?: string
    },
    silent_update?: boolean,
  ) => {
    // When user update the delivery drawer we add this updated prop as true, and here we check if we need to save
    const deliveriesToUpdate = orderStore.selectedOrder?.deliveries?.filter((delivery) => delivery?.updated)

    if (deliveriesToUpdate.length) {
      await Promise.all(
        deliveriesToUpdate?.map((delivery) => deliveryStore.updateDelivery({ ...delivery, silent_update: true })),
      )
    }

    return orderStore
      .updateOrder(
        {
          state_changes,
          purchaser_files_signed_ids: uploaderStore.signedIds('purchaser_files'),
          purchaser_files_delete_ids: uploaderStore.deleteAttachmentIds['purchaser_files'],
          quote_signed_id: _.get(uploaderStore.signedIds('quote'), '[0]'),
          silent_update,
        },
        userStore.canCreateNewMaterial,
      )
      .then(() => orderStore.setOrderDirty())
  }

  const handlePlaceOrder = async () => {
    if (!uploaderStore.checkIfAllUploadsCompleted()) {
      Modal.error({ title: 'Uploads have not completed yet, please try again.' })
      return
    }
    const orderMaterials = orderStore.filteredOrderedMaterials()
    const errors = orderStore.validateRequiredFields(orderMaterials, requiredOrderFields)
    if (errors.length) {
      Modal.error({ title: `Please fill in the following required fields: ${errors.join(', ')}.` })
      return
    }

    const projectErrors = validateProject()
    if (projectErrors.length) {
      Modal.error({ title: projectErrors[0] })
      return
    }

    setActionClicked(2)
    maybeAddWatcher()

    try {
      await saveOrder({ cancelled_at: null, ordered_at: moment().toISOString() }, false)

      if (syncOrderWithIntegration) {
        integrationStore.createOrder(orderStore.selectedOrder?.id)
      }

      setPurchaseOrderModalVisible(false)
      goBack()

      notifyOrder({ message: 'Placed order' })
    } catch (error) {
      handleLogError(error, 'Unable to place the order.')
    } finally {
      setActionClicked(-1)
    }
  }

  const handleCancelOrder = async () => {
    if (orderStore.anyDelivered) {
      Modal.error({ title: 'You cannot cancel an order that has already been delivered.' })
      return
    }
    if (!uploaderStore.checkIfAllUploadsCompleted()) {
      Modal.error({ title: 'Uploads have not completed yet, please try again.' })
      return
    }
    const orderMaterials = orderStore.filteredOrderedMaterials()
    const errors = orderStore.validateRequiredFields(orderMaterials, requiredOrderFields)
    if (errors.length) {
      Modal.error({ title: `Please fill in the following required fields: ${errors.join(', ')}.` })
      return
    }

    const projectErrors = validateProject()
    if (projectErrors.length) {
      Modal.error({ title: projectErrors[0] })
      return
    }

    setActionClicked(1)

    try {
      const orderType = orderStore.selectedOrder?.state === OrderStates.QUOTED ? 'RFQ' : 'Order'

      await saveOrder({ cancelled_at: moment().toISOString() }, false)

      goBack()

      notifyOrder({ message: `Cancelled ${orderType}` })
    } catch (error) {
      handleLogError(error, 'Unable to cancel the order.')
    } finally {
      setActionClicked(-1)
    }
  }

  const handleSave = async ({
    stateChanges,
    action,
    silentUpdate,
    isBack = true,
  }: {
    stateChanges?: { cancelled_at?: Nullable<string> }
    action?: number
    silentUpdate?: boolean
    isBack?: boolean
  }) => {
    if (stateChanges?.['cancelled_at'] && orderStore.anyDelivered) {
      Modal.error({ title: 'You cannot cancel an order that has already been delivered.' })
      return
    }
    if (!uploaderStore.checkIfAllUploadsCompleted()) {
      Modal.error({ title: 'Uploads have not completed yet, please try again.' })
      return
    }
    const orderMaterials = orderStore.filteredOrderedMaterials()
    const errors = orderStore.validateRequiredFields(orderMaterials, requiredOrderFields)
    if (errors.length) {
      Modal.error({ title: `Please fill in the following required fields: ${errors.join(', ')}.` })
      return
    }

    const projectErrors = validateProject()
    if (projectErrors.length) {
      Modal.error({ title: projectErrors[0] })
      return
    }

    setActionClicked(action)
    maybeAddWatcher()

    try {
      await saveOrder(stateChanges, silentUpdate)

      const orderType =
        [OrderStates.QUOTED].includes(orderStore.selectedOrder?.state) ||
        [OrderSubStates.CANCELLED_QUOTE_BY_BUYER].includes(orderStore.selectedOrder.sub_state)
          ? 'RFQ'
          : 'Order'

      notifyOrder({ message: `Updated ${orderType}` })

      if (isBack) {
        goBack()
      }
    } catch (error) {
      handleLogError(error)
    } finally {
      setActionClicked(-1)
    }
  }

  const handleRequestCancellation = async (cancellationReason: string) => {
    setActionClicked(1)

    try {
      await saveOrder(
        {
          cancellation_requested_at: moment().toISOString(),
          cancellation_requested_reason: cancellationReason,
          cancellation_accepted_at: null,
          cancellation_rejected_at: null,
        },
        false,
      )

      goBack()

      notifyOrder({ message: 'Cancellation requested' })
    } catch (error) {
      handleLogError(error, 'Unable to request cancelation of the order.')
    } finally {
      setActionClicked(-1)
    }
  }

  const handleRollbackRequestCancellation = async () => {
    setActionClicked(1)

    try {
      await saveOrder(
        {
          cancellation_requested_at: null,
          cancellation_requested_reason: null,
          cancellation_accepted_at: null,
          cancellation_rejected_at: null,
        },
        false,
      )

      goBack()

      notifyOrder({ message: 'Cancellation Withdrawn' })
    } catch (error) {
      handleLogError(error, 'Unable to request cancelation of the order.')
    } finally {
      setActionClicked(-1)
    }
  }

  const handleSendInternalComment = async ({ text, userIds }) => {
    setSubmitting(true)
    try {
      await orderStore.sendInternalComment({ text, userIds: [...userIds, userStore.currentUser?.id] })
    } catch (error) {
      message.error('Unable to send message')
    } finally {
      setSubmitting(false)
    }
  }

  const handleDirty = () => {
    orderStore.setOrderDirty()
  }

  const editablePackageName = userStore.canManageOrders &&
    userStore.canSendAndUpdateOrders && {
      onChange: (value = '') => orderStore.updateSelectedOrder('order_package_name', value),
    }

  if (isLoadingOrder || isLoading) {
    return <Loading />
  }

  if (!orderStore.selectedOrder) {
    return null
  }

  const { state, sub_state, order_package_name, deliveries = [], manual_exported_at, id } = orderStore.selectedOrder

  const orderTypeTitle = getOrderTitleType(state)

  const canShowIntegration =
    userStore.canUseIntegrations &&
    statesToShowIntegration.includes(orderStore.selectedOrder?.state) &&
    integrationStore.connected &&
    integrationStore.purchaseOrderSyncEnabled

  const canShowTCMV2Download =
    tcmCsvV2DownloadEnabled && statesToShowIntegration.includes(orderStore.selectedOrder?.state)

  const canShowFoundationDownload =
    foundationCsvDownloadEnabled && statesToShowIntegration.includes(orderStore.selectedOrder?.state)

  orderStore.orderChangedModal = () => {
    confirm({
      title: 'This Order has changed',
      content: (
        <>
          <p>
            Another user did some changes to this Order.
            <br />
            Do you want to refresh the Order data?
          </p>
          <p>
            <b>NOTE: </b>
            <i>Refreshing will discard your changes!</i>
          </p>
        </>
      ),
      icon: <ExclamationCircleFilled />,
      onOk: async () => {
        await orderStore.selectOrder(params['id'])
      },
      onCancel: () => {
        destroyAll()
      },
      okText: 'Yes',
      cancelText: 'No',
    })
  }

  const orderActionProps = {
    onFinish: handleSave,
    actionClicked: actionClicked,
    onPlaceOrder: handlePlaceOrder,
    onCancelOrder: handleCancelOrder,
    purchaseOrderModalVisible: purchaseOrderModalVisible,
    togglePurchaseOrderModalVisible: setPurchaseOrderModalVisible,
    syncOrderWithIntegration: syncOrderWithIntegration,
    toggleSyncOrderWithIntegration: setSyncOrderWithIntegration,
    onRequestCancellation: handleRequestCancellation,
    isPoLocked,
    isSpreadsheetMode,
    toggleSpreadsheetMode: () => setSpreadsheetMode(!isSpreadsheetMode),
  }

  return (
    <>
      <Page>
        <Page.Header showShadow={!isMobileScreen}>
          <PageHeader
            backIcon={
              orderStore.selectedOrderDirty ? (
                <Popconfirm
                  title="You may have unsaved changes, are you sure you want to exit?"
                  onConfirm={() => goBack()}
                  okText="Yes"
                  cancelText="No"
                  placement="bottomLeft"
                >
                  <ArrowLeftIcon />
                </Popconfirm>
              ) : (
                <ArrowLeftIcon onClick={() => goBack()} />
              )
            }
            onBack={() => null}
            title={
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" justifyContent={{ _: 'space-between', md: 'flex-start' }}>
                  <Box display="flex" alignItems="center" mb={2} mt={{ _: 8, md: 0 }}>
                    <Typography.Text>{orderTypeTitle}</Typography.Text>
                  </Box>
                  <Box ml={{ _: 0, sm: 20 }} display="flex" alignItems="center" style={{ gap: 8 }}>
                    <Visibility.Show breakpoint="md">
                      <InternalComments
                        onSubmit={handleSendInternalComment}
                        loading={isSubmitting}
                        comments={orderStore.selectedOrder?.internal_comments}
                        orderState={orderStore.selectedOrder?.state}
                        orderSubState={orderStore.selectedOrder?.sub_state}
                      />
                    </Visibility.Show>

                    <Visibility.Show breakpoint="md">
                      <History />
                    </Visibility.Show>

                    {showLockIcon && (
                      <Box display="flex" alignItems="flex-end" flexDirection={{ _: 'column', xs: 'row' }}>
                        <LockPo
                          cannotUpdateOrders={userStore.cannotSendAndUpdateOrders}
                          role={userStore?.currentUser?.role}
                          rule={companySettingStore?.otherSettings?.lock_order_settings?.lock_order_rule}
                        />
                      </Box>
                    )}

                    <Box display="flex" alignItems="flex-end" flexDirection={{ _: 'column', xs: 'row' }}>
                      <OrderState
                        state={state}
                        sub_state={sub_state}
                        showIssue={deliveries?.some((d) => d.has_open_issue)}
                        deliveryIssues={deliveries?.map((d) => d.delivery_issue_type)}
                        size="medium"
                      />
                    </Box>
                    {showOrderConfirmOverride && !isPoLocked && (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        marginLeft="8"
                        marginRight="16"
                      >
                        <Tooltip
                          title="Mark the order as confirmed. This will not send an update to your vendor."
                          placement="bottom"
                        >
                          <Button
                            type="default"
                            size="small"
                            icon={<CheckOutlined style={{ fontSize: '10px' }} />}
                            onClick={() => orderStore.confirmOrder()}
                            shape="circle"
                            disabled={isPoLocked}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>

                  <Visibility.Hidden breakpoint="lg">
                    <QuoteValidUntil />
                  </Visibility.Hidden>
                  {/* TCM it's just a fake integration, the user can just download and CSV and upload it in their ERP, it's controlled by feature flag */}
                  {canShowTCMV2Download && !isPoLocked && (
                    <Visibility.Hidden breakpoint="lg">
                      <TCMSyncStatusDetailed
                        isSynced={!!manual_exported_at}
                        onClick={() => orderStore.manual_export([{ id }]).then(() => orderStore.selectOrder(id))}
                        order={orderStore.selectedOrder}
                        canSyncWithErp={userStore.canSyncWithErp}
                      />
                    </Visibility.Hidden>
                  )}

                  {canShowFoundationDownload && (
                    <Visibility.Hidden breakpoint="lg">
                      <FoundationSyncStatusDetailed
                        isSynced={!!manual_exported_at}
                        onClick={() => orderStore.manual_export([{ id }]).then(() => orderStore.selectOrder(id))}
                      />
                    </Visibility.Hidden>
                  )}
                  {canShowIntegration && (
                    <Visibility.Hidden breakpoint="lg">
                      {orderStore.selectedOrder && <IntegrationSync order={orderStore.selectedOrder} />}
                    </Visibility.Hidden>
                  )}
                </Box>

                <Visibility.Show breakpoint="lg">
                  <QuoteValidUntil />
                </Visibility.Show>

                <Visibility.Show>
                  <Typography.Title type="secondary" style={{ margin: 0 }} level={5} editable={editablePackageName}>
                    {order_package_name}
                  </Typography.Title>
                </Visibility.Show>

                {canShowTCMV2Download && (
                  <Visibility.Show breakpoint="lg">
                    <TCMSyncStatusDetailed
                      isSynced={!!manual_exported_at}
                      onClick={() => orderStore.manual_export([{ id }]).then(() => orderStore.selectOrder(id))}
                      order={orderStore.selectedOrder}
                      canSyncWithErp={userStore.canSyncWithErp}
                    />
                  </Visibility.Show>
                )}

                {canShowFoundationDownload && (
                  <Visibility.Show breakpoint="lg">
                    <FoundationSyncStatusDetailed
                      isSynced={!!manual_exported_at}
                      onClick={() => orderStore.manual_export([{ id }]).then(() => orderStore.selectOrder(id))}
                    />
                  </Visibility.Show>
                )}

                {canShowIntegration && (
                  <Visibility.Show breakpoint="lg">
                    {orderStore.selectedOrder && <IntegrationSync order={orderStore.selectedOrder} />}
                  </Visibility.Show>
                )}
              </Box>
            }
            extra={[
              <Visibility.Hidden breakpoint="md" key="internal-comments">
                <InternalComments
                  onSubmit={handleSendInternalComment}
                  loading={isSubmitting}
                  comments={orderStore.selectedOrder?.internal_comments}
                  orderState={orderStore.selectedOrder?.state}
                  orderSubState={orderStore.selectedOrder?.sub_state}
                />
              </Visibility.Hidden>,
              <Visibility.Hidden breakpoint="md" key="history">
                <History />
              </Visibility.Hidden>,
              <Visibility.Hidden breakpoint="md" key="extras">
                <OrderActions {...orderActionProps} />
              </Visibility.Hidden>,
            ]}
          />

          <Visibility.Hidden breakpoint="md">
            <OrderInfo isPoLocked={isPoLocked} />
          </Visibility.Hidden>

          <OrderBlockedHeader {...orderSession} isRFQ={isRFQ} />
        </Page.Header>

        <Visibility.Show breakpoint="md">
          <Page.Tabs
            tabBarGutter={24}
            onChange={(activeKey) => {
              trackEvent(Events.CHANGE_ORDER_DETAIL_TAB, location.pathname.slice(1), {
                active_key: activeKey,
              })
            }}
            items={[
              {
                label: 'Details',
                key: 'details',
                children: (
                  <Box pt={24} pb={112} width="100%">
                    {orderStore.selectedOrder?.quick_created_at && (
                      <QuickPOAlert style={{ width: '100%', marginBottom: 12 }} />
                    )}
                    <WithdrawCancelation
                      cancellationRequestedAt={orderStore.selectedOrder?.cancellation_requested_at}
                      cancellationAcceptedAt={orderStore.selectedOrder?.cancellation_accepted_at}
                      cancellationRejectedAt={orderStore.selectedOrder?.cancellation_rejected_at}
                      cancellationRequestedReason={orderStore.selectedOrder?.cancellation_requested_reason}
                      onClick={handleRollbackRequestCancellation}
                      isSubmitting={actionClicked > 0}
                    />
                    <OrderInfo isPoLocked={isPoLocked} />
                  </Box>
                ),
              },
              {
                label: 'Materials',
                key: 'materials',
                children: (
                  <Box pt={24} pb={112} width="100%">
                    {orderStore.selectedOrder?.quick_created_at && (
                      <QuickPOAlert style={{ width: '100%', marginBottom: 12 }} />
                    )}
                    <MaterialsAndDeliveries isPoLocked={isPoLocked} isSpreadsheetMode={isSpreadsheetMode} />
                  </Box>
                ),
              },
              !!orderStore.selectedOrder?.invoices?.length &&
                userStore.canUseInvoices && {
                  label: 'Invoices',
                  key: 'invoices',
                  children: <Invoices />,
                },
              {
                label: 'Vendor Chat',
                key: 'chat',
                children: <TabVendorChat />,
              },
              {
                label: 'Attachments',
                key: 'attachments',
                children: <TabAttachments isPoLocked={isPoLocked} canInputVendorData={userStore.canInputVendorData} />,
              },
            ]}
          />
        </Visibility.Show>

        <Visibility.Hidden breakpoint="md">
          <Page.Content display="flex" flexDirection="column" p={0} px={{ _: 0, sm: 16 }} py={16} mb={56}>
            {showQuoteAlert && (
              <QuoteAlert
                quoteFullyMatched={quoteFullyMatched}
                setShowQuoteDrawer={setShowQuoteDrawer}
                state={quoteStore?.quote?.status}
              />
            )}

            {showQuoteAlert && (
              <QuoteReconciliationDrawer
                state={orderStore?.selectedOrder?.quote_extraction?.quote_state}
                closeDrawer={() => setShowQuoteDrawer(false)}
                showDrawer={showQuoteDrawer}
                pdfUrl={orderStore?.selectedOrder?.quote?.url}
              />
            )}

            <WithdrawCancelation
              cancellationRequestedAt={orderStore.selectedOrder?.cancellation_requested_at}
              cancellationAcceptedAt={orderStore.selectedOrder?.cancellation_accepted_at}
              cancellationRejectedAt={orderStore.selectedOrder?.cancellation_rejected_at}
              cancellationRequestedReason={orderStore.selectedOrder?.cancellation_requested_reason}
              onClick={handleRollbackRequestCancellation}
              isSubmitting={actionClicked > 0}
            />

            <Box mb={16} flex={1} overflowY="auto">
              {orderStore.selectedOrder?.quick_created_at && (
                <Col span={24}>
                  <QuickPOAlert />
                </Col>
              )}

              <MaterialsAndDeliveries
                isPoLocked={isPoLocked}
                onDirty={handleDirty}
                isSpreadsheetMode={isSpreadsheetMode}
              />
            </Box>

            <Tabs canInputVendorData={userStore.canInputVendorData} isPoLocked={isPoLocked} />
          </Page.Content>
        </Visibility.Hidden>

        <Visibility.Show breakpoint="md">
          <OrderActions {...orderActionProps} isFloat />
        </Visibility.Show>

        <UnloadWarning
          showWarning={() => orderStore.selectedOrderDirty}
          onSaveAndLeave={() => handleSave({ isBack: false, action: 2 })}
          isSubmitting={actionClicked > 0}
        />
      </Page>

      <OrderBlockedModals
        {...orderSession}
        onCancelModalOrderExpired={() => goBack()}
        onCancelModalOrderReleased={() => goBack()}
        isRFQ={isRFQ}
      />

      <Total
        value={orderStore.totalCost}
        precision={companySettingStore.otherSettings?.rounding_precision_settings?.order_precision}
      />
    </>
  )
})
