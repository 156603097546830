import React from 'react'

import { EditableTableContextProvider } from 'common/components/EditableTable/context'

import { useFlag } from 'contractor/hooks/use-flag'

import { InvoiceProvider } from './context'
import { InvoiceDetailExtract } from './invoice_detail_extract'
import { InvoiceDetailOriginal } from './invoice_detail_original'

export const InvoiceDetail = () => {
  const extractedInvoiceDataEnabled = useFlag('extracted_invoice_data')

  if (extractedInvoiceDataEnabled) {
    return (
      <InvoiceProvider>
        <EditableTableContextProvider>
          <InvoiceDetailExtract />
        </EditableTableContextProvider>
      </InvoiceProvider>
    )
  }

  return <InvoiceDetailOriginal />
}
