// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api
export const noticeError = (error, customAttributes = {}) => {
  if (window?.NREUM) {
    window.NREUM.noticeError(error, customAttributes)
  } else {
    console.error('[NREUM] is not defined')
  }
}

interface CustomAttributes {
  userId?: string
  [key: string]: string | boolean | number
}

export const setUserInfo = (customAttributes: CustomAttributes = {}) => {
  if (window?.NREUM) {
    Object.keys(customAttributes).forEach((key) => {
      window.NREUM.setCustomAttribute(key, customAttributes[key])
    })

    window.NREUM.setCustomAttribute('screenWidth', window.screen.width)
    window.NREUM.setCustomAttribute('screenHeight', window.screen.height)
  } else {
    console.error('[NREUM] is not defined')
  }
}
