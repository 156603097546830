import React from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Spin, Tooltip, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { UploaderList } from 'common/components/@v2/UploaderList'
import { UploaderTitle } from 'common/components/@v2/UploaderTitle'
import { Box } from 'common/components/boxes'
import { Events, trackEvent } from 'common/pendo/event_tracking'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

const statesToShowPO = [
  OrderStates.ORDERED,
  OrderStates.PARTIALLY_SHIPPED,
  OrderStates.SHIPPED,
  OrderStates.DELIVERED,
  OrderStates.CANCELLED,
]

const VENDOR_FILES_KEY = 'vendor_files'
const PURCHASER_FILES_KEY = 'purchaser_files'
const PURCHASER_ORDER_FILE_KEY = 'purchase_order'
const REQUEST_FOR_QUOTE_FILE_KEY = 'request_for_quote'
const QUOTE_FILE_KEY = 'quote'

interface AttachmentsProps {
  canInputVendorData?: boolean
  isPoLocked?: boolean
  opened?: boolean
}

export const TabAttachments = observer(({ opened = true, canInputVendorData, isPoLocked }: AttachmentsProps) => {
  const { orderStore, uploaderStore, userStore, companySettingStore } = useStores()

  const downloadRequestForQuoteEnabled = companySettingStore?.otherSettings?.email_settings?.include_pdf_attachments

  const { purchaser_files, purchase_order, request_for_quote, quote, vendor_files } = orderStore.selectedOrder

  const editDisabled = userStore.cannotSendAndUpdateOrders || isPoLocked

  const isCanceled = orderStore.selectedOrder?.state === OrderStates.CANCELLED
  const showPurchaseOrderLoading = !isCanceled && !purchase_order

  React.useEffect(() => {
    ;[
      VENDOR_FILES_KEY,
      PURCHASER_FILES_KEY,
      PURCHASER_ORDER_FILE_KEY,
      REQUEST_FOR_QUOTE_FILE_KEY,
      QUOTE_FILE_KEY,
    ].forEach((key) => {
      uploaderStore.resetUploads(key)
    })

    uploaderStore.addExistingFiles(VENDOR_FILES_KEY, vendor_files)
    uploaderStore.addExistingFiles(PURCHASER_FILES_KEY, purchaser_files)
    uploaderStore.addExistingFiles(PURCHASER_ORDER_FILE_KEY, purchase_order)
    uploaderStore.addExistingFiles(REQUEST_FOR_QUOTE_FILE_KEY, request_for_quote)
    uploaderStore.addExistingFiles(QUOTE_FILE_KEY, quote)
  }, [orderStore.selectedOrder?.id, orderStore.selectedOrder?.updated_at])

  if (!opened) return null

  return (
    <Box display="flex" flexDirection={{ _: 'column', lg: 'row' }} width={{ _: '100%', lg: '60%' }}>
      <Box
        display="flex"
        flexDirection="column"
        width={{ _: '100%', lg: '50%' }}
        p={16}
        maxHeight={{ _: '100%', md: 228 }}
        overflow="overlay"
      >
        <Box mb={8}>
          <UploaderTitle
            title="My Attachments"
            multiple
            disabled={editDisabled}
            uploadKey={PURCHASER_FILES_KEY}
            onChange={() => orderStore.setOrderDirty()}
            fileList={uploaderStore.fileList(PURCHASER_FILES_KEY)}
            onRemoveUpload={uploaderStore.removeUpload}
            onAddNewUpload={uploaderStore.addNewUpload}
            onResetUploads={uploaderStore.resetUploads}
            onSetUploadError={uploaderStore.setUploadError}
            onUpdateUpload={uploaderStore.updateUpload}
            uploadButtonText="Upload"
          />
        </Box>

        {orderStore.selectedOrder?.state === OrderStates.QUOTED && downloadRequestForQuoteEnabled && (
          <UploaderList
            disabled
            multiple={false}
            uploadKey={REQUEST_FOR_QUOTE_FILE_KEY}
            title="Request For Quote"
            fileList={uploaderStore.fileList(REQUEST_FOR_QUOTE_FILE_KEY)}
          />
        )}

        {userStore.canUseAutoPoGeneration && statesToShowPO.includes(orderStore.selectedOrder?.state) && (
          <>
            <UploaderList
              disabled
              multiple={false}
              uploadKey={PURCHASER_ORDER_FILE_KEY}
              title="Purchase Order"
              fileList={uploaderStore.fileList(PURCHASER_ORDER_FILE_KEY)}
              onPreview={(file) => {
                trackEvent(Events.DOWNLOAD_PO, window.location.href, {
                  file_name: file?.name,
                  user_id: userStore.currentUser.id,
                  user_email: userStore.currentUser.email,
                  company_id: userStore.currentUser.company_id,
                  company_name: userStore.currentUser.company_name,
                })
              }}
            />
            {showPurchaseOrderLoading && (
              <Tooltip title="Your PO will be generated in a few seconds." placement="topLeft">
                <Space style={{ width: 'max-content' }}>
                  <Spin indicator={<LoadingOutlined spin style={{ fontSize: 14 }} />} />
                  <Typography.Text type="secondary">Loading...</Typography.Text>
                </Space>
              </Tooltip>
            )}
          </>
        )}

        <Box mt={12}>
          <UploaderList
            title="Other Attachments"
            multiple
            disabled={editDisabled}
            uploadKey={PURCHASER_FILES_KEY}
            onChange={() => orderStore.setOrderDirty()}
            fileList={uploaderStore.fileList(PURCHASER_FILES_KEY)}
            onRemoveUpload={uploaderStore.removeUpload}
            onAddNewUpload={uploaderStore.addNewUpload}
            onResetUploads={uploaderStore.resetUploads}
            onSetUploadError={uploaderStore.setUploadError}
            onUpdateUpload={uploaderStore.updateUpload}
          />
        </Box>
      </Box>

      <Box
        flexGrow={1}
        my={12}
        borderRightStyle="solid"
        borderBottomStyle="solid"
        borderRightWidth={{ _: 'none', sm: '1px' }}
        borderBottomWidth={{ _: '1px', sm: 'none' }}
        borderRightColor="gray-4"
        borderBottomColor="gray-4"
      />

      <Box
        display="flex"
        flexDirection="column"
        width={{ _: '100%', lg: '50%' }}
        p={16}
        maxHeight={{ _: '100%', md: 228 }}
        overflow="overlay"
      >
        <Box mb={8}>
          <UploaderTitle
            title="Vendor Attachments"
            multiple={false}
            uploadKey={QUOTE_FILE_KEY}
            disabled={!canInputVendorData || isPoLocked}
            uploadButtonText={canInputVendorData ? 'Attach quote' : null}
            onRemoveUpload={uploaderStore.removeUpload}
            onAddNewUpload={uploaderStore.addNewUpload}
            onResetUploads={uploaderStore.resetUploads}
            onSetUploadError={uploaderStore.setUploadError}
            onUpdateUpload={uploaderStore.updateUpload}
            onChange={orderStore.setOrderDirty}
            fileList={uploaderStore.fileList(QUOTE_FILE_KEY)}
            modalTitle="Quote"
          />
        </Box>

        <UploaderList
          multiple={false}
          uploadKey={QUOTE_FILE_KEY}
          title="Quote"
          disabled={!canInputVendorData || isPoLocked}
          onRemoveUpload={uploaderStore.removeUpload}
          onAddNewUpload={uploaderStore.addNewUpload}
          onResetUploads={uploaderStore.resetUploads}
          onSetUploadError={uploaderStore.setUploadError}
          onUpdateUpload={uploaderStore.updateUpload}
          onChange={orderStore.setOrderDirty}
          fileList={uploaderStore.fileList(QUOTE_FILE_KEY)}
        />
        <Box mt={12}>
          <UploaderList
            multiple
            uploadKey={VENDOR_FILES_KEY}
            title="More Attachments"
            disabled
            onChange={orderStore.setOrderDirty}
            fileList={uploaderStore.fileList(VENDOR_FILES_KEY)}
          />
        </Box>
      </Box>
    </Box>
  )
})
