import React from 'react'

import { usePsqlTable } from 'common/components/PsqlTable/psql_table_provider'
import { formatDateStringShort } from 'common/helpers/formatters'

import { DownloadButton } from 'contractor/components/DownloadButton'
import { useFlag } from 'contractor/hooks/use-flag'
import { ConsolidatedOrders } from 'contractor/server/orders'

interface MapData {
  project_name: string
  project_number: string
  order_number: string
  name: string
  created_at: string
  updated_at: string
  delivery_state: Array<string>
  delivery_date: Array<string>
  vendor_name: string
  state: string
  sub_state: string
  ordered_by_name: string
  requested_by_name: string
  ordered_at: string
  grand_total: string
}

interface Props {
  onLoadData: () => Promise<Array<ConsolidatedOrders.Order>>
}
export function Download(props: Props) {
  const { state } = usePsqlTable()

  const externalVendorIdEnabled = useFlag('external_vendor_id')

  function dataMapper(order: ConsolidatedOrders.Order) {
    const result = {
      ...order,
      order_created_at: formatDateStringShort(order.order_created_at),
      order_updated_at: formatDateStringShort(order.order_updated_at),
      ordered_at: formatDateStringShort(order.ordered_at),
      delivery_state: order.deliveries.map((delivery) => delivery.state),
      delivery_date: order.deliveries.map((delivery) => formatDateStringShort(delivery.best_guess_delivered_at)),
      grand_total: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(order.grand_total),
      company_vendor: order.company_vendor_id ? { id: order.company_vendor_id } : {},
      vendor_name: order.vendor_name,

      deliveries: order.deliveries.map((delivery) => {
        delivery['requested_delivered_at'] = formatDateStringShort(delivery['requested_delivered_at'])
        delivery['best_guess_delivered_at'] = formatDateStringShort(delivery['best_guess_delivered_at'])
        return delivery
      }),
    }

    if (externalVendorIdEnabled) {
      result.vendor_name = order.vendor_name
    }

    return result
  }

  const headers = [
    { label: 'Project Name', key: 'project_name' },
    { label: 'Project ID', key: 'project_number', groupedKey: 'project_name' },
    { label: 'Order Number', key: 'order_number' },
    { label: 'Order Name', key: 'name', groupedKey: 'order_number' },
    { label: 'Created At', key: 'order_created_at' },
    { label: 'Updated At', key: 'order_updated_at', groupedKey: 'order_created_at' },
    { label: 'Delivery State', key: 'delivery_state' },
    { label: 'Delivery Date', key: 'delivery_date', groupedKey: 'delivery_state' },
    { label: 'Vendor', key: 'vendor_name' },
    { label: 'Status', key: 'state' },
    { label: 'Sub Status', key: 'sub_state', groupedKey: 'order_number' },
    { label: 'Ordered By', key: 'ordered_by_name' },
    { label: 'Requested by', key: 'requested_by_name' },
    { label: 'Ordered At', key: 'ordered_at' },
    { label: 'Grand Total', key: 'grand_total' },
  ].filter((header) => !state.hiddenColumns.includes(header.key) && !state.hiddenColumns.includes(header.groupedKey))

  return (
    <DownloadButton<ConsolidatedOrders.Order, MapData>
      dataMapper={dataMapper}
      title="Orders"
      filename="orders"
      headers={headers}
      onLoadData={props.onLoadData}
    />
  )
}
