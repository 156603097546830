import axios from 'axios'

import { IntegrationsEntities, IntegrationsSourceSystem } from 'common/server/server_types'

export interface ApproveRejectMapping {
  suggestion_ids: string[]
}

export interface UpdateMapping {
  id: string
  external_id: string
}

export interface BasicRelationship {
  id: string
  external_id: string
  external_name: string
  tied_to_id?: Nullable<string>
}

export enum SuggestionAction {
  APPROVED = 'approve',
  REJECTED = 'rejected',
}

export interface MappingSuggestion {
  suggestion_id?: string
  internal_id?: string
  internal_name?: string
  external_id?: string
  external_name?: string
}

export interface Relationship {
  id: string
  extras: {
    external_payload: Record<string, unknown>
  }
  external_id: string
  internal_id: string
  external_entity: string
  internal_entity: string
  external_name: string
  internal_name: string
  tied_to_id?: Nullable<string>
  mapping_suggestions?: MappingSuggestion[]
}

export interface SearchParams {
  search?: string
  tied_to_id?: string
}

export interface ListParams extends SearchParams {
  unmapped?: boolean
  mapped?: boolean
  page?: number
  per_page?: number
}

type Config = 'enabled' | 'disabled'

export interface SyncingProgress {
  syncing_init_data: boolean
  init_data_progress: number
  init_data_failed: boolean
  init_data_failed_message?: string
  step_description: string
}

export interface AccountingConfig {
  purchase_order_sync?: Config
  invoice_sync?: Config
  map_cost_codes_as_items?: Config
  invoice_sync_type?: 'expense' | 'line_item'
  po_sync_type?: 'expense' | 'line_item'
  po_sync_status?: 'Draft' | 'Approved'
  order_tax_behavior?: string
  invoice_tax_behavior?: string
}

export interface AccountingIntegration extends SyncingProgress {
  id: string
  integrations?: IntegrationsSourceSystem
  purchase_order_sync?: Config
  invoice_sync?: Config
  invoice_sync_type?: 'expense' | 'line_item'
  po_sync_type?: 'expense' | 'line_item'
  allow_multi_mapping?: 'enabled' | 'disabled'
  multi_mapping_entities?: IntegrationsEntities[]
  map_cost_codes_as_items: boolean
  status?: 'online' | 'offline'
  po_sync_status?: 'Draft' | 'Approved'
  importing_sub_jobs: boolean
  order_tax_behavior: string
  invoice_tax_behavior: string
  logging_out: boolean
  mappings?: {
    projects: 'enabled' | 'disabled'
    vendors: 'enabled' | 'disabled'
    cost_codes: 'enabled' | 'disabled'
    cost_classes: 'enabled' | 'disabled'
    cost_phase: 'enabled' | 'disabled'
    materials: 'enabled' | 'disabled'
  }
}

export interface ClientLastAction {
  date_time?: string
  log?: string
}

export interface AccountingIntegrationToken extends AccountingIntegration {
  link_token?: string
}

export function accounting_info() {
  return axios.get<AccountingIntegration>(`/integrations/accounting/info`)
}

export function accounting() {
  return axios.get<AccountingIntegrationToken>(`/integrations/accounting`)
}

export function client_last_actions() {
  return axios.get<ClientLastAction>(`/integrations/accounting/client_last_actions`)
}

export function accounting_token(public_token: string) {
  return axios.post<AccountingIntegration>(`/integrations/accounting`, { accounting: { public_token } })
}

export function change_status(status: 'online' | 'offline') {
  return axios.patch<AccountingIntegration>(`/integrations/accounting/status`, { status })
}

export function accounting_config(params: AccountingConfig) {
  return axios.post<AccountingIntegration>('/integrations/accounting/config', params)
}

export function logout() {
  return axios.post<{ message: string }>('/integrations/accounting/logout', {})
}

export function approve_suggestions(approvedSuggestions: ApproveRejectMapping) {
  return axios.patch(`/integrations/accounting/suggestions/approve`, approvedSuggestions)
}

export function reject_suggestions(rejectedSuggestions: ApproveRejectMapping) {
  return axios.patch(`/integrations/accounting/suggestions/reject`, rejectedSuggestions)
}
