import React, { useState } from 'react'

import { Divider, Form, Radio, Typography, Input, message, Button, Row, Col } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX, Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { formatName } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'

import { AddressForm } from 'contractor/components/Addresses'
import { SelectProjectGroup } from 'contractor/components/SelectProjectGroup/select_project_group'
import { WatchersSelect } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceAssignees } from 'contractor/pages/ProjectSettings/invoice_assignees'

type CreateProjectModalProps = {
  onCancel: () => void
  onSubmit?: (projectId: string) => void
}

export const CreateProjectModal = observer<CreateProjectModalProps, DrawerRef>(
  ({ onCancel, onSubmit }, ref) => {
    const { projectGroupsStore, userStore, projectStore, addressStore, projectInvoiceAssigneeStore } = useStores()

    const [withAddress, setWithAddress] = useState(true)
    const [isSubmitting, setSubmitting] = useState(false)

    const [form] = Form.useForm()

    const { isLoading } = useQuery(projectInvoiceAssigneeStore.getAllProjectInvoiceAssignees)

    const handleCreateProject = async (formValues) => {
      setSubmitting(true)

      try {
        const projectId = await projectStore.createProject({
          name: formValues?.name,
          default_watcher_user_ids: formValues?.defaultWatchers.map((defaultWatcher) => defaultWatcher.value),
          project_group_id: formValues?.projectGroupId,
          project_id: formValues?.projectIdentifier,
          project_invoice_assignees: formValues?.projectInvoiceAssignees
            ?.filter((projectInvoiceAssignee) => !!projectInvoiceAssignee?.userId)
            ?.map((projectInvoiceAssignee) => ({
              id: projectInvoiceAssignee?.id,
              user_id: projectInvoiceAssignee?.userId,
            })),
        })

        if (withAddress) {
          const address = {
            address_line_1: formValues?.address_line_1,
            address_line_2: formValues?.address_line_2,
            address_to: formValues?.address_to,
            city: formValues?.city,
            nickname: formValues?.nickname,
            phone_number: formValues?.phone_number,
            state: formValues?.state,
            zip_code: formValues?.zip_code,
          }
          await projectStore.addAddress(projectId, address)
          addressStore.indexAddresses()
        }

        await projectStore.indexProjects()
        onSubmit?.(projectId)
        onCancel()
      } catch (err) {
        if (err?.response?.data?.error) {
          message.error(err?.response?.data?.error)
        } else {
          message.error('Unable to create project.')
        }
      } finally {
        setSubmitting(false)
      }
    }

    const isProjectGroupRequired = !!projectGroupsStore.projectGroups.length

    if (isLoading) {
      return <Loading />
    }

    return (
      <Drawer ref={ref} bgGray title="Create a new project">
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            defaultWatchers: [
              {
                value: userStore.currentUser?.id,
                label: formatName(userStore.currentUser?.first_name, userStore.currentUser?.last_name),
              },
            ],
            projectInvoiceAssignees: projectInvoiceAssigneeStore.projectInvoiceAssignees,
          }}
          onFinish={handleCreateProject}
          scrollToFirstError
        >
          <Box p={16} width="100%" height="100%" overflowY="auto">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="name"
                  label="Project Name"
                  rules={[{ required: true, message: 'Project Name is required!' }]}
                >
                  <Input data-cy="project-name" placeholder="e.g. True North, 123 Main St, etc." />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="projectIdentifier"
                  label="Project ID"
                  hasFeedback
                  rules={[{ pattern: new RegExp('^\\S*$'), message: 'White spaces are not allowed' }]}
                >
                  <Input data-cy="project-id" placeholder="e.g. TN123" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="defaultWatchers"
                  label="Default Order Watchers"
                  tooltip="Any time a request, quote, or order is created for this project you will automatically be added as a watcher to receive updates."
                >
                  <WatchersSelect />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item
                  name="projectGroupId"
                  label="Project Group"
                  rules={[{ required: isProjectGroupRequired, message: 'Project Group is required!' }]}
                >
                  <SelectProjectGroup data-cy="project-group" style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              {userStore.canUseInvoices && (
                <InvoiceAssignees colProps={{ xs: 24, sm: 12, lg: 'auto', xl: 'auto', xxl: 'auto' }} />
              )}
            </Row>

            <Divider />

            <FlexBoxY>
              <FlexBoxX justifyContent="flex-start" width="100%">
                <Radio.Group
                  options={[
                    { label: 'Add project address', value: true },
                    { label: 'Add address later', value: false },
                  ]}
                  onChange={() => {
                    setWithAddress((prev) => !prev)
                  }}
                  value={withAddress}
                  optionType="button"
                  buttonStyle="solid"
                />
              </FlexBoxX>

              {withAddress && (
                <>
                  <FlexBoxX justifyContent="flex-start" width="100%" my="12px">
                    <Typography.Text strong>Main Project Address</Typography.Text>
                  </FlexBoxX>
                  <AddressForm form={form} />
                </>
              )}
            </FlexBoxY>

            <Divider />

            <Box display="flex" justifyContent="space-between">
              <Button onClick={onCancel} loading={isSubmitting} style={{ width: 100 }}>
                Cancel
              </Button>

              <Button
                data-cy="create-project"
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                style={{ width: 100 }}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Form>
      </Drawer>
    )
  },
  { forwardRef: true },
)
