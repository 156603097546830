import React, { useState } from 'react'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { Typography, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { UnsavedPopup } from 'common/components/Page/unsaved_popup'
import { Visibility } from 'common/components/Visibility'
import { InvoicesStates, RelationshipStockStatus } from 'common/server/server_types'

import { AgaveSyncStatusDetailed } from 'contractor/components/AgaveSyncStatusDetailed'
import { LockInvoice } from 'contractor/components/Invoices/lock_invoice'
import { useLockInvoice } from 'contractor/hooks/use-lock-invoice'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { IntegrationSync } from '../integration_sync'
import { HeaderProps } from './header'

type LeftActionsProps = Pick<
  HeaderProps,
  'goBack' | 'isSubmitting' | 'onChangeName' | 'onSaveInvoice' | 'onConfirmSync'
>

export const LeftActions = observer<LeftActionsProps>((props) => {
  const { goBack, isSubmitting, onSaveInvoice, onConfirmSync, onChangeName } = props

  const { invoiceStore, integrationStore, userStore, invoiceStateStore } = useStores()

  const { selectedInvoiceDirty } = useInvoice()
  const isInvoiceLocked = useLockInvoice(invoiceStore?.invoice)

  const [integrationModalOpen, toggleIntegrationModal] = useState(false)

  const canShowIntegration =
    userStore.canUseIntegrations && integrationStore.connected && integrationStore.invoiceSyncEnabled

  const postedState = invoiceStateStore.invoiceStates?.find(({ state }) => state === InvoicesStates.POSTED)

  const isInvoicePosted = invoiceStore?.invoice?.state?.id === postedState?.id
  const isIntegrationFailedOrNotsynced = [RelationshipStockStatus.FAILED, RelationshipStockStatus.NOT_SYNCED].includes(
    invoiceStore.invoice?.integration?.status,
  )

  const showAction = isInvoicePosted && isIntegrationFailedOrNotsynced && userStore.canPostInvoices

  return (
    <Box display="flex" alignItems="center">
      <Box mr={16}>
        {selectedInvoiceDirty ? <UnsavedPopup goBack={goBack} /> : <ArrowLeftOutlined onClick={() => goBack()} />}
      </Box>

      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        <Box display="inline-flex" alignItems="center" style={{ gap: 16 }}>
          <Tooltip title="Name" placement="bottom">
            <Typography.Title
              aria-label="invoice-name-title"
              style={{ margin: 0 }}
              level={5}
              editable={{
                onChange: onChangeName,
                onCancel: () => onChangeName(invoiceStore.invoice?.name || ''),
              }}
            >
              {invoiceStore.invoice?.name || 'Invoice'}
            </Typography.Title>
          </Tooltip>

          {isInvoiceLocked && (
            <Box m="0" pl="8" mt="4">
              <LockInvoice />
            </Box>
          )}

          <Visibility.Hidden breakpoint="md">
            {canShowIntegration && (
              <>
                <AgaveSyncStatusDetailed
                  integration={invoiceStore.invoice?.integration}
                  integrationName={integrationStore.getIntegrationName(invoiceStore.invoice?.integration?.source)}
                  webUrl={invoiceStore.invoice?.integration?.web_url}
                  onClick={() => toggleIntegrationModal(true)}
                  showAction={showAction}
                />
                <IntegrationSync
                  onClose={() => toggleIntegrationModal(false)}
                  open={integrationModalOpen}
                  onFinish={onConfirmSync}
                  onFinishWithoutSync={onSaveInvoice}
                  isSubmitting={isSubmitting}
                />
              </>
            )}
          </Visibility.Hidden>
        </Box>
      </Box>
    </Box>
  )
})
