import React, { useRef } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Row, Space, Typography } from 'antd'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { InputCurrencyProps } from 'common/components/InputCurrency'
import { InputDeliveryPercentage } from 'common/components/InputCurrencyPercentage/Delivery/input_delivery_percentage'
import { currencyFormatter } from 'common/helpers/formatters'
import { DeliveryChargesUnit } from 'common/server/deliveries'

type Value = {
  discountValue: number
  shippingValue: number
  otherValue: number
  taxValue: number
}

export type OrderDeliveryCostsTotalProps = {
  onChange?: (value: Value) => void
  deliveryTotalCost?: number
  hideAddTotal?: boolean
  index: number
  value: Value
  disabled?: boolean
  costsDisabled?: boolean
  isPoLocked?: boolean
  roundingPrecision?: number
  orderMaterialsTotalCost?: number
  inputsProps?: Partial<Record<DeliveryExtraValues, InputCurrencyProps>>
  deliveryChargesUnits?: DeliveryChargesUnit
  onChangeChargesUnit?: (value: DeliveryChargesUnit) => void
  deliveryName: string
}

export enum DeliveryExtraValues {
  Discount = 'discount_value',
  Shipping = 'shipping_value',
  Other = 'other_value',
  Tax = 'tax_value',
}

export const OrderDeliveryCostsTotal = (props: OrderDeliveryCostsTotalProps) => {
  const drawerCostsRef = useRef<DrawerRef>()

  const {
    onChange,
    hideAddTotal,
    index,
    value,
    roundingPrecision,
    isPoLocked,
    costsDisabled,
    inputsProps,
    orderMaterialsTotalCost,
    deliveryChargesUnits,
    onChangeChargesUnit,
    deliveryName,
  } = props

  const currentValues = {
    [DeliveryExtraValues.Shipping]: Number(value?.shippingValue),
    [DeliveryExtraValues.Other]: Number(value?.otherValue),
    [DeliveryExtraValues.Tax]: Number(value?.taxValue),
    [DeliveryExtraValues.Discount]: Number(value?.discountValue),
  }

  const totalCost =
    currentValues[DeliveryExtraValues.Shipping] +
    currentValues[DeliveryExtraValues.Other] +
    currentValues[DeliveryExtraValues.Tax] -
    currentValues[DeliveryExtraValues.Discount]

  const handleSubmit = () => {
    onChange?.({
      shippingValue: currentValues[DeliveryExtraValues.Shipping],
      otherValue: currentValues[DeliveryExtraValues.Other],
      taxValue: currentValues[DeliveryExtraValues.Tax],
      discountValue: currentValues[DeliveryExtraValues.Discount],
    })
    drawerCostsRef.current?.close()
  }

  const humanizeKey = (key: string) => {
    return key
      .split('_')[0]
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  const handleChange = (value, key) => {
    currentValues[key] = value
  }

  return (
    <>
      <FlexBoxX justifyContent="flex-end" flexGrow={0}>
        <Space direction="horizontal" align="start" size="middle">
          {!hideAddTotal && (
            <Button
              disabled={isPoLocked || costsDisabled}
              data-cy={`extras-total-${index}`}
              size="small"
              type="link"
              onClick={() => drawerCostsRef.current?.show()}
            >
              Add Cost
            </Button>
          )}

          {!!totalCost && (
            <>
              <FlexBoxY alignItems="flex-end" py="1px">
                <Typography.Text data-cy="grand-total-material-text">
                  {'Additional costs '}
                  <Typography.Text strong>{currencyFormatter(totalCost || 0, roundingPrecision || 3)}</Typography.Text>
                </Typography.Text>
              </FlexBoxY>

              <FlexBoxY alignItems="flex-end" py="1px">
                /
              </FlexBoxY>
            </>
          )}

          <FlexBoxY alignItems="flex-end" py="1px">
            <Typography.Text data-cy="grand-total-material-text">
              {`Total (${deliveryName}) `}
              <Typography.Text strong>
                {currencyFormatter(orderMaterialsTotalCost || 0, roundingPrecision || 3)}
              </Typography.Text>
            </Typography.Text>
          </FlexBoxY>
        </Space>
      </FlexBoxX>

      <Drawer
        ref={drawerCostsRef}
        width={378}
        height="max-content"
        title={
          <FlexBoxX justifyContent="space-between">
            <Typography.Text strong>Additional costs</Typography.Text>
            <Button type="text" size="small" onClick={handleSubmit} icon={<CloseOutlined />} />
          </FlexBoxX>
        }
        onClose={handleSubmit}
        closable={false}
        placement="bottom"
      >
        <Box display="flex" flex={1} alignItems="center" justifyContent="space-between" margin={16}>
          <Row gutter={[16, 16]}>
            {Object.keys(currentValues).map((key) => {
              const currentValue = currentValues[key] || 0
              const inputProps = inputsProps?.[key]

              return (
                <Col key={key} xs={24} sm={12} lg={6}>
                  <Row gutter={[8, 8]}>
                    <Col xs={6} md={7} style={{ alignSelf: 'center' }}>
                      <Typography.Text>{humanizeKey(key)}:</Typography.Text>
                    </Col>
                    <Col xs={18} md={17}>
                      <InputDeliveryPercentage
                        data-cy={`extras-input-${key}-${index}`}
                        style={{ maxWidth: '180px' }}
                        size="middle"
                        totalCost={orderMaterialsTotalCost}
                        roundingPrecision={roundingPrecision}
                        inputName={key}
                        onChangeChargesUnit={onChangeChargesUnit}
                        deliveryChargesUnits={deliveryChargesUnits}
                        value={currentValue}
                        onChange={(value) => {
                          const valueNumber = Number(value)
                          if (!isNaN(valueNumber)) {
                            handleChange(valueNumber, key)
                          }
                        }}
                        {...inputProps}
                        disabled={isPoLocked || costsDisabled || inputProps?.disabled}
                      />
                    </Col>
                  </Row>
                </Col>
              )
            })}
          </Row>
        </Box>
      </Drawer>
    </>
  )
}
