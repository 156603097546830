import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import { uniq } from 'lodash'

import styled from '@emotion/styled'

import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Card } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { useTheme } from 'common/hooks/use-theme'
import { InvoiceResponse } from 'common/server/invoice'

import { useLockInvoice } from 'contractor/hooks/use-lock-invoice'
import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from './context'
import {
  EmptyMatches,
  EmptyNoMatches,
  EmptyPartialMatches,
  getInvoiceMaterialsColumns,
  getInvoiceMaterialsExpandableColumns,
  InvoiceMaterials,
} from './InvoiceMaterials'
import { OrderMaterial, OrderSearch } from './OrderDetailNew'
import { OrdersTags } from './OrdersTags'

type ReconcileStepProps = {
  attachedOrderIds: string[]
  onAttachOrder: (orderId: Nullable<string>) => void
  onUnAttachOrder: (orderId: Nullable<string>) => void
  isLoading?: boolean
  visible: boolean
  saveInvoice: (autoMatch?: boolean, orderIds?: string[]) => Promise<InvoiceResponse>
}

const Wrapper = styled(Box)`
  .ant-table .ant-table-thead {
    position: sticky;
    top: 33px;
    z-index: 1;
  }
`

export const ReconcileStep = observer<ReconcileStepProps>(
  ({ attachedOrderIds = [], onAttachOrder, onUnAttachOrder, isLoading, visible, saveInvoice }) => {
    const { invoiceStore } = useStores()

    const theme = useTheme()

    const { calculatedGrandTotal } = useInvoice()

    const [filterByInvoiceMaterials, setFilterByInvoiceMaterials] = useState(() => {
      return invoiceStore.invoice?.invoice_materials?.some(
        (invoiceMaterial) => !invoiceMaterial?.order_materials?.length,
      )
        ? ['no-matches']
        : ['all']
    })
    const [filterByOrders, setFilterByOrders] = useState(['all'])

    const history = useHistory()

    const invoice = invoiceStore.invoice
    const isInvoiceLocked = useLockInvoice(invoice)

    const columns = getInvoiceMaterialsColumns()
      .filter(
        (column) =>
          !['remove', 'cost_code', 'tax', 'ext_cost_with_tax', 'cost_code_phase_id', 'retainage'].includes(
            column.dataIndex,
          ),
      )
      .map((column) => {
        return {
          ...column,
          editable: () => false,
        }
      })

    const expandableColumns = getInvoiceMaterialsExpandableColumns().filter(
      (column) => !['order', 'cost_code'].includes(column.dataIndex as string),
    )

    const handleFilterInvoiceMaterialsTable = (invoiceMaterial) => {
      if (filterByInvoiceMaterials.includes('all')) {
        return true
      }

      const extractedQuantity = Number(invoiceMaterial?.quantity_shipped || 0)
      const invoiceUnit = invoiceMaterial?.uom?.trim() || ''

      const orderMaterialsQuantities = uniq(
        invoiceMaterial?.order_materials?.map(
          (orderMaterial) => (orderMaterial?.quantity_delivered ?? orderMaterial?.quantity) || 0,
        ),
      )
      const orderMaterialsUnits = uniq(
        invoiceMaterial?.order_materials?.map(
          (orderMaterial) =>
            orderMaterial?.company_material?.unit?.name?.trim() ||
            orderMaterial?.company_material?.unit_name?.trim() ||
            '',
        ),
      )

      const hasOrderMaterials = !!invoiceMaterial?.order_materials?.length

      if (filterByInvoiceMaterials.includes('matches')) {
        return (
          hasOrderMaterials &&
          orderMaterialsQuantities?.length <= 1 &&
          orderMaterialsQuantities[0] === extractedQuantity &&
          orderMaterialsUnits?.length <= 1 &&
          invoiceUnit === orderMaterialsUnits[0]
        )
      }

      if (filterByInvoiceMaterials.includes('partial-matches')) {
        return (
          hasOrderMaterials &&
          (orderMaterialsQuantities?.length > 1 ||
            orderMaterialsUnits?.length > 1 ||
            orderMaterialsQuantities[0] !== extractedQuantity ||
            invoiceUnit !== orderMaterialsUnits[0])
        )
      }

      return !invoiceMaterial?.order_materials?.length
    }

    const handleFilteOrderMaterialsTable = (orderMaterial) => {
      if (filterByOrders.includes('all')) {
        return true
      }

      return filterByOrders.includes(orderMaterial?.['order_id'])
    }

    const getEmptyComponent = React.useCallback(() => {
      if (filterByInvoiceMaterials.includes('no-matches')) {
        return (
          <EmptyNoMatches onClick={() => history.push({ search: new URLSearchParams({ step: '2' }).toString() })} />
        )
      }
      if (filterByInvoiceMaterials.includes('partial-matches')) {
        return <EmptyPartialMatches />
      }
      if (filterByInvoiceMaterials.includes('matches')) {
        return <EmptyMatches />
      }
    }, [filterByInvoiceMaterials])

    return (
      <Wrapper
        display={visible ? 'grid' : 'none'}
        gridTemplateColumns="2fr 1fr"
        height="fit-content"
        minHeight="100%"
        width="100%"
        minWidth={1200}
        style={{ gap: 8 }}
      >
        <Box display="flex" flexDirection="column">
          <RibbonFilter
            boxProps={{
              position: 'sticky',
              top: -15,
              zIndex: 1,
              padding: '8px 0',
              backgroundColor: theme.colors['gray-2'],
            }}
            value={filterByInvoiceMaterials}
            onChange={setFilterByInvoiceMaterials}
            options={[
              { label: 'All', filter: 'all' },
              {
                label: 'Matches',
                filter: 'matches',
                rightIcon: <CheckCircleOutlined style={{ color: theme.colors.success }} />,
              },
              {
                label: 'Partial Matches',
                filter: 'partial-matches',
                rightIcon: <WarningOutlined style={{ color: theme.colors.warning }} />,
              },
              {
                label: 'No Matches',
                filter: 'no-matches',
                rightIcon: <ExclamationCircleOutlined style={{ color: theme.colors.error }} />,
              },
            ]}
          />

          <Card
            style={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            bodyStyle={{ padding: 10, flexGrow: 1 }}
          >
            <InvoiceMaterials
              columns={columns}
              onFilterTable={handleFilterInvoiceMaterialsTable}
              expandableColumns={expandableColumns}
              showExpandable
              empty={getEmptyComponent()}
            />
          </Card>
        </Box>

        <Box display="flex" flexDirection="column">
          <OrdersTags
            disabled={isInvoiceLocked}
            saveInvoice={saveInvoice}
            attachedOrderIds={attachedOrderIds}
            onAttachOrder={onAttachOrder}
            onUnAttachOrder={onUnAttachOrder}
            value={filterByOrders}
            onChange={setFilterByOrders}
          />

          <Card
            style={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            bodyStyle={{ padding: 10, flexGrow: 1 }}
          >
            {attachedOrderIds?.length ? (
              <OrderMaterial isLoading={isLoading} onFilterTable={handleFilteOrderMaterialsTable} />
            ) : (
              <OrderSearch
                saveInvoice={saveInvoice}
                onSelect={(order) => onAttachOrder(order?.id)}
                onCreated={(order) => onAttachOrder(order?.id)}
                initialValues={{
                  grandTotal: calculatedGrandTotal,
                  orderNumber: invoice?.extracted_order_number,
                  companyVendorId: invoice?.company_vendor_id,
                }}
              />
            )}
          </Card>
        </Box>
      </Wrapper>
    )
  },
)
