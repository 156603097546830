import React from 'react'

import { Button, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { DateRangePicker } from 'common/components/DateRangePicker'
import { SelectFilter } from 'common/components/table/postgres_filters'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

export const InvoiceFilters = () => {
  const { invoiceStore, userStore, invoiceSettingsStore } = useStores()
  const { handleChangeFilters, handleClearFilters } = usePersistentFilters()

  const { listStore } = invoiceStore

  const accountingDateEnabled = invoiceSettingsStore?.settings?.accounting_date_enabled

  const sync_status_options = (listStore.facets['sync_statuses'] || []).map((x) => ({ label: x, value: x }))
  const assigned_to_options = (listStore.facets['assigned_to_labels'] || []).map((x) => ({ label: x, value: x }))
  const company_vendor_name_options = (listStore.facets['company_vendor_names'] || []).map((x) => ({
    label: x,
    value: x,
  }))

  const tags_options = (listStore.facets['tags'] || []).map((x) => ({ label: x, value: x }))
  const appliedFiltersAndCounters = Object.keys(listStore.searchState.filters).filter(
    (filter) => !['state_name', 'project_id'].includes(filter),
  ).length

  return (
    <Box display="flex" flexDirection="column" p="12" gridGap={16}>
      <Box width="100%" display="flex" flexDirection="column">
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Vendor</Typography.Text>

          <SelectFilter
            items={company_vendor_name_options}
            selected={listStore.searchState.filters['company_vendor_name']}
            onChange={(value) => handleChangeFilters({ company_vendor_name: value })}
            attribute="company_vendor_name"
            placeholder="Select by vendor"
            isSearchable
          />
        </Box>

        {userStore.canUseIntegrations && (
          <Box mb={16} width="100%" display="flex" flexDirection="column">
            <Typography.Text type="secondary">Sync Status</Typography.Text>

            <SelectFilter
              items={sync_status_options}
              selected={listStore.searchState['filters']['sync_status']}
              onChange={(value) => handleChangeFilters({ sync_status: value })}
              attribute="agave_sync_status"
              placeholder="Select sync status"
              isSearchable
            />
          </Box>
        )}

        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Assigned to</Typography.Text>

          <SelectFilter
            items={assigned_to_options}
            attribute="assigned_to_label"
            placeholder="Select by assignment"
            selected={listStore.searchState['filters']['assigned_to_label']}
            onChange={(value) => handleChangeFilters({ assigned_to_label: value })}
          />
        </Box>
        <Box mb={16} width="100%" display="flex" flexDirection="column">
          <Typography.Text type="secondary">Tags</Typography.Text>

          <SelectFilter
            items={tags_options}
            attribute="tags"
            placeholder="Select tag"
            selected={listStore.searchState['filters']['tags']}
            onChange={(value) => handleChangeFilters({ tags: value })}
          />
        </Box>

        <Box width="100%" mb={16}>
          <Typography.Text type="secondary">Invoice Date Range</Typography.Text>

          <DateRangePicker
            data-cy="invoice-date-range"
            placeholderStart="Start date"
            placeholderEnd="End date"
            attribute="invoice_date_timestamp"
            value={{
              startDate: listStore.searchState.filters['invoice_date_start'],
              endDate: listStore.searchState.filters['invoice_date_end'],
            }}
            onChange={({ startDate, endDate }) => {
              handleChangeFilters({
                invoice_date_start: startDate,
                invoice_date_end: endDate,
              })
            }}
          />
        </Box>

        <Box width="100%" mb={16}>
          <Typography.Text type="secondary">Created At</Typography.Text>

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            value={{
              startDate: listStore.searchState.filters['created_at_start'],
              endDate: listStore.searchState.filters['created_at_end'],
            }}
            onChange={({ startDate, endDate }) => {
              handleChangeFilters({
                created_at_start: startDate,
                created_at_end: endDate,
              })
            }}
          />
        </Box>

        {accountingDateEnabled && (
          <Box width="100%" mb={24}>
            <Typography.Text type="secondary">Accounting Date Range</Typography.Text>

            <DateRangePicker
              placeholderStart="Start date"
              placeholderEnd="End date"
              attribute="accounting_date_timestamp"
              value={{
                startDate: listStore.searchState.filters['accounting_date_start'],
                endDate: listStore.searchState.filters['accounting_date_end'],
              }}
              onChange={({ startDate, endDate }) => {
                handleChangeFilters({
                  accounting_date_start: startDate,
                  accounting_date_end: endDate,
                })
              }}
            />
          </Box>
        )}

        <Button
          style={{ marginTop: 16, width: '100%' }}
          type="primary"
          data-cy="clear-filters"
          onClick={() => handleClearFilters()}
          disabled={appliedFiltersAndCounters === 0}
        >
          Clear All
        </Button>
      </Box>
    </Box>
  )
}
