import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react'

import { flatten } from 'lodash'

import { Form, FormInstance } from 'antd'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { currencyFormatter as currencyFormatterDefault } from 'common/helpers/formatters'
import { calcExtCost as calcExtCostDefault, calcInvoiceTaxSplitBulk } from 'common/helpers/order'
import { InvoiceResponse } from 'common/server/invoice'

import { useStores } from 'contractor/hooks/use-stores'

import { DraggableType } from './InvoiceMaterials'

const InvoiceContext = createContext<InvoiceContextProps>({} as InvoiceContextProps)

export const useInvoice = () => useContext(InvoiceContext)

type InvoiceContextProps = {
  form: FormInstance
  selectedInvoiceDirty: boolean
  setSelectedInvoiceDirty: React.Dispatch<React.SetStateAction<boolean>>
  handleUnmatch: (orderMaterialId: string) => void
  handleToggleInvoiceOrderMaterialDrawer: (orderId: string) => void
  invoiceOrderMaterialDrawerRef: React.MutableRefObject<DrawerRef>
  calculatedEligibleTaxSplitTotal: number
  calculatedSubtotal: number
  calculatedGrandTotal: number
  calculatedMobileGrandTotal: number
  selectedOrderIdOnDrawer: Nullable<string>
  taxAmountField: number
  shippingCostField: number
  discountAmountField: number
  otherCostsField: number
  allInvoicesExceptTheCurrent: InvoiceResponse[]
  calcExtCost: (params: {
    unitCost: number
    quantity: number
    multiplier?: number | string
    qtyIncrement?: number | string
  }) => number
  currencyFormatter: (value: number) => string
  calcTaxSplitBulk: (params: {
    taxAmount: number
    invoiceMaterials: InvoiceResponse['invoice_materials']
    taxLineItemsEnabled: boolean
  }) => InvoiceResponse['invoice_materials']
  taxLineItemsEnabled?: boolean
}

export const InvoiceProvider = observer(({ children }) => {
  const { invoiceStore } = useStores()

  const invoiceOrderMaterialDrawerRef = useRef<DrawerRef>()
  const [selectedOrderIdOnDrawer, setSelectedOrderIdOnDrawer] = useState(null)

  const [selectedInvoiceDirty, setSelectedInvoiceDirty] = useState(false)
  const [form] = Form.useForm()

  const taxAmountField = Form.useWatch('taxAmount', form) || 0
  const shippingCostField = Form.useWatch('shippingCost', form) || 0
  const discountAmountField = Form.useWatch('discountAmount', form) || 0
  const otherCostsField = Form.useWatch('otherCosts', form) || 0

  const invoice = invoiceStore.invoice

  const taxLineItemsEnabled = React.useMemo(
    () => invoice?.project?.tax_line_items?.enabled || [...(invoice?.projects || [])]?.[0]?.tax_line_items?.enabled,
    [invoice?.project?.tax_line_items?.enabled, invoice?.projects],
  )

  const [allInvoicesExceptTheCurrent, setAllInvoicesExceptTheCurrent] = useState<InvoiceResponse[]>([])
  /*
    We need to show all invoices information in the review step.
    Since the current invoice might have N orders related, every order also might be
    related to N other invoices.
    To do it, we need to follow these steps:
    1. Load the current invoice.
    2. Load all orders related to the current invoice.
    3. Load all invoices related to the orders.
    In this point we have the 1 and 2 steps done. Now we need to load all invoices related to the orders.
  */
  useEffect(() => {
    if (invoiceStore.selectedOrders?.length && invoice?.id) {
      const invoicesIds = flatten(
        invoiceStore.selectedOrders.map(({ order }) => order.invoices.map(({ id }) => id)),
      ).filter((id) => id !== invoice.id)

      Promise.all(invoicesIds.map((invoiceId) => invoiceStore.getInvoiceById(invoiceId))).then(
        setAllInvoicesExceptTheCurrent,
      )
    }
  }, [invoiceStore.selectedOrders.length, invoice?.id])

  const handleUnmatch = useCallback(
    (orderMaterialId) => {
      const invoiceMaterials = invoiceStore.invoice.invoice_materials.map((invoiceMaterial) => {
        if (invoiceMaterial?.order_materials?.some((orderMaterial) => orderMaterial.id === orderMaterialId)) {
          return {
            ...invoiceMaterial,
            order_materials: invoiceMaterial.order_materials?.filter(
              (orderMaterial) => orderMaterial.id !== orderMaterialId,
            ),
          }
        }

        return invoiceMaterial
      })
      invoiceStore.updateSelectedInvoice('invoice_materials', invoiceMaterials)

      const selectedOrdersMaterials = invoiceStore.selectedOrdersMaterials.map((orderMaterial) => {
        if (orderMaterialId === orderMaterial.id) {
          return {
            ...orderMaterial,
            is_invoiced: false,
          }
        }

        return orderMaterial
      })
      invoiceStore.updateSelectedOrdersMaterials(selectedOrdersMaterials)
      setSelectedInvoiceDirty(true)
    },
    [invoiceStore.invoice?.invoice_materials?.length],
  )

  const handleToggleInvoiceOrderMaterialDrawer = useCallback((orderId) => {
    setSelectedOrderIdOnDrawer(orderId)
    invoiceOrderMaterialDrawerRef.current?.toggle()
  }, [])

  const calcExtCost = useCallback(
    (params) => {
      return calcExtCostDefault({ ...params, precision: invoice?.decimal_precision })
    },
    [invoice?.decimal_precision],
  )

  const currencyFormatter = useCallback(
    (value) => currencyFormatterDefault(value, invoice?.decimal_precision, invoice?.decimal_precision),
    [invoice?.decimal_precision],
  )

  const calcTaxSplitBulk = useCallback(
    (params) => calcInvoiceTaxSplitBulk({ ...params, precision: invoice?.decimal_precision }),
    [invoice?.decimal_precision],
  )

  const calculatedEligibleTaxSplitTotal = React.useMemo(() => {
    return invoice?.invoice_materials
      ?.filter((invoiceMaterial) => invoiceMaterial.accepts_tax_split)
      ?.reduce((acc, invoiceMaterial) => {
        const extendedCost =
          calcExtCost({
            unitCost: invoiceMaterial?.unit_price,
            quantity: invoiceMaterial?.quantity_shipped,
            multiplier: invoiceMaterial?.unit?.multiplier,
            qtyIncrement: invoiceMaterial?.unit?.qty_increment,
          }) || 0
        return acc + extendedCost
      }, 0)
  }, [invoice?.invoice_materials, calcExtCost])

  const calculatedSubtotal = React.useMemo(() => {
    return invoice?.invoice_materials.reduce((acc, invoiceMaterial) => {
      const extendedCost =
        calcExtCost({
          unitCost: invoiceMaterial?.unit_price,
          quantity: invoiceMaterial?.quantity_shipped,
          multiplier: invoiceMaterial?.unit?.multiplier,
          qtyIncrement: invoiceMaterial?.unit?.qty_increment,
        }) || 0
      return acc + extendedCost
    }, 0)
  }, [invoice?.invoice_materials, calcExtCost])

  const calculatedGrandTotal = React.useMemo(() => {
    const taxAmount = Number(taxAmountField) || 0
    const shippingAmount = Number(shippingCostField) || 0
    const discountAmount = Number(discountAmountField) || 0
    const otherCosts = Number(otherCostsField) || 0

    return calculatedSubtotal + taxAmount + shippingAmount + otherCosts - discountAmount
  }, [taxAmountField, shippingCostField, discountAmountField, otherCostsField, calculatedSubtotal])

  // We need this mobile version because in mobile we do not render the form and we need to get the value from the invoice
  const calculatedMobileGrandTotal = React.useMemo(() => {
    const taxAmount = Number(invoice?.tax_amount)
    const shippingAmount = Number(invoice?.shipping_cost)
    const discountAmount = Number(invoice?.discount_amount)
    const otherCosts = Number(invoice?.other_costs)

    return calculatedSubtotal + taxAmount + shippingAmount + otherCosts - discountAmount
  }, [calculatedSubtotal, invoice?.tax_amount, invoice?.shipping_cost, invoice?.discount_amount, invoice?.other_costs])

  useEffect(() => {
    if (invoiceStore.selectedOrdersMaterials.length) {
      const orderMaterials = invoiceStore.selectedOrdersMaterials?.map((orderMaterial) => ({
        ...orderMaterial,
        type: DraggableType[DraggableType.ORDER_MATERIAL],
      }))
      invoiceStore.updateSelectedOrdersMaterials(orderMaterials)
    }
  }, [invoiceStore.invoice?.id, invoiceStore.selectedOrdersMaterials.length])

  useEffect(() => {
    if (invoiceStore.invoice) {
      const invoiceMaterials = invoiceStore.invoice?.invoice_materials?.map((invoiceMaterial) => ({
        ...invoiceMaterial,
        type: DraggableType[DraggableType.INVOICE_MATERIAL],
      }))
      invoiceStore.updateSelectedInvoice('invoice_materials', invoiceMaterials)
    }
  }, [invoiceStore.invoice?.id])

  useEffect(() => {
    return () => {
      invoiceStore.resetStore()
    }
  }, [])

  return (
    <InvoiceContext.Provider
      value={{
        form,
        selectedInvoiceDirty,
        setSelectedInvoiceDirty,
        handleUnmatch,
        handleToggleInvoiceOrderMaterialDrawer,
        selectedOrderIdOnDrawer,
        invoiceOrderMaterialDrawerRef,
        calculatedEligibleTaxSplitTotal,
        calculatedSubtotal,
        calculatedGrandTotal,
        calculatedMobileGrandTotal,
        taxAmountField,
        shippingCostField,
        discountAmountField,
        otherCostsField,
        allInvoicesExceptTheCurrent,
        calcExtCost,
        currencyFormatter,
        calcTaxSplitBulk,
        taxLineItemsEnabled,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  )
})
