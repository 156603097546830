import React from 'react'

import { useHistory } from 'react-router-dom'

import { Button, Empty, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'
import { InvoiceInboxRibbonStates, InvoiceSourceType } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceInboxItem } from 'contractor/pages/@v2/Invoices/components/invoice_inbox_item'
import { InvoiceInboxesFilter } from 'contractor/pages/@v2/Invoices/components/invoice_inboxes_filters'
import { InvoicingEmailCopy } from 'contractor/pages/@v2/Invoices/components/invoicing_email_copy'

import { InvoiceInboxesDetails } from './invoice_inboxes_details'

function Content() {
  const { companySettingStore, invoiceInboxStore } = useStores()

  const detailDrawerRef = React.useRef<DrawerRef>()
  const history = useHistory()

  const persistentFilters = usePersistentFilters()

  function handleSearch(value: string) {
    persistentFilters.handleChangeFilters({ search: value }, false)
  }

  function handleChangeRibbonFilter(value: string[]) {
    persistentFilters.handleChangeFilters({ state: value })
  }

  function handleOpenInboxItemDetails(inboxId: string, sourceType: InvoiceSourceType) {
    detailDrawerRef.current?.show()
    history.push(
      `/invoices?tab=invoice_inboxes&invoices_inbox_source_id=${inboxId}&invoices_inbox_source_type=${sourceType}`,
    )
  }

  const { data } = useQuery(
    () =>
      Promise.all([
        companySettingStore.invoicingMail(),
        invoiceInboxStore.listStore.fetchFacets(),
        persistentFilters.init(),
      ]),
    [],
  )
  const invoicingMail = data && data[0]

  return (
    <>
      <Page>
        <Page.Header>
          <Box width="100%" display="flex" alignItems="initial" flexDirection={{ _: 'column', md: 'row' }}>
            <Box width="100%" display="flex" alignItems="center" flexGrow={1}>
              <Box mr={8} flexGrow={1}>
                <SearchInput
                  value={invoiceInboxStore.listStore.searchState.search}
                  onSearch={handleSearch}
                  style={{ width: '100%' }}
                />
              </Box>

              <InvoiceInboxesFilter
                values={invoiceInboxStore.listStore.searchState.filters}
                facets={invoiceInboxStore.listStore.facets}
              />
            </Box>
          </Box>

          <Box
            mt={16}
            display="flex"
            gridGap={16}
            flexDirection={{ _: 'column', md: 'row' }}
            alignItems={{ _: 'flex-start', md: 'center' }}
            justifyContent="space-between"
            width="100%"
          >
            <RibbonFilter
              showCounts
              value={invoiceInboxStore.listStore.searchState.filters['state'] || []}
              counts={invoiceInboxStore.listStore.stateCounts || {}}
              onChange={handleChangeRibbonFilter}
              boxProps={{ overflowX: 'auto' }}
              options={[
                { label: 'All', filter: '' },
                { label: 'Extracting', filter: InvoiceInboxRibbonStates.EXTRACTING },
                { label: 'Ignored', filter: InvoiceInboxRibbonStates.IGNORED },
                { label: 'Failed', filter: InvoiceInboxRibbonStates.FAILED },
                { label: 'Created', filter: InvoiceInboxRibbonStates.CREATED },
              ]}
            />

            <InvoicingEmailCopy copyable type="secondary">
              {invoicingMail}
            </InvoicingEmailCopy>
          </Box>
        </Page.Header>
        <Page.Content p={0} px={{ _: 0, md: 12, lg: 16 }} pb={24} pt={{ _: 0, md: 16 }} height="100%">
          {invoiceInboxStore.listStore.isFetching && invoiceInboxStore.listStore.searchState.page === 1 ? (
            <Loading />
          ) : invoiceInboxStore.listStore.records.length === 0 ? (
            <Box
              px={{ _: 12, lg: 24 }}
              pb={{ _: 12, lg: 24 }}
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <Typography.Title level={5} style={{ textAlign: 'center' }}>
                    Sorry! No Items were found with these search criteria.
                  </Typography.Title>
                }
              />
            </Box>
          ) : (
            <>
              {invoiceInboxStore.listStore.records.map((record) => {
                return (
                  <InvoiceInboxItem
                    key={record.id}
                    onClick={() => handleOpenInboxItemDetails(record.invoice_source_id, record.invoice_source_type)}
                    inbox={record}
                  />
                )
              })}

              {invoiceInboxStore.listStore.hasMore && (
                <FlexBoxX width="100%" p={16} bg="white">
                  <Button
                    type="link"
                    loading={invoiceInboxStore.listStore.isFetching}
                    onClick={invoiceInboxStore.listStore.fetchNextPage}
                  >
                    Load More
                  </Button>
                </FlexBoxX>
              )}
            </>
          )}
        </Page.Content>
      </Page>

      <InvoiceInboxesDetails />
    </>
  )
}

export const InvoiceInboxesContent = observer(Content)
