import React from 'react'

import { OrdersList } from 'common/components/OrdersList'
import { PsqlTableCells } from 'common/components/PsqlTable'
import { PsqlColumn } from 'common/components/PsqlTable/psql_table_provider'
import OrderState from 'common/components/statuses/order_state'
import { formatEnumValue } from 'common/helpers/formatters'
import { usePushToOrder } from 'common/hooks/use-push-to-order'

import { OrderMaterialCells } from 'contractor/pages/@v2/OrderMaterials/components/cells'
import { Cell } from 'contractor/pages/@v2/Orders/components/cells'
import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials/consolidated_order_materials'

interface Props {
  ignoreProjectColumns?: boolean
  companyAttributes?: Array<string>
  statusColumn: {
    getNotificationsCount: (id: string) => number
  }
  costCodeColumn: {
    phaseEnabled: boolean
    classEnabled: boolean
  }
}

export function columnsFactory(props: Props): Array<PsqlColumn<ConsolidatedOrdersMaterials.OrderMaterial>> {
  const { ignoreProjectColumns, companyAttributes = [], statusColumn, costCodeColumn } = props

  const { getUrl } = usePushToOrder()

  const baseColumns: Array<PsqlColumn<ConsolidatedOrdersMaterials.OrderMaterial>> = [
    {
      Header: 'Status',
      id: 'order_state',
      accessor: 'order_state',
      sortBy: 'order_state',
      width: 176,
      Cell: ({ row: { original: item } }) => (
        <OrderState
          state={item.order_state}
          sub_state={item.order_sub_state}
          deliveryIssues={[item.delivery_issue_type]}
          notificationCount={statusColumn.getNotificationsCount(item.order_id)}
          showIssue={item.delivery_has_open_issue}
        />
      ),
    },
    {
      Header: 'Description',
      id: 'company_material.description',
      accessor: 'company_material_description',
      sortBy: 'company_material_description',
      ellipsis: true,
      width: 300,
      Cell: ({ value, column }) => (
        <PsqlTableCells.FormattedText value={value} column={column} style={{ maxWidth: 300 }} />
      ),
    },
    {
      Header: 'Order',
      id: 'order_number',
      accessor: 'order_number',
      sortBy: 'order_number',
      width: 400,
      Cell: ({
        row: {
          original: { order_id, order_name, order_number, order_state, order_sub_state, order_package_id },
        },
      }) => {
        const pathToOpenOrder = getUrl({
          orderId: order_id,
          orderPackageId: order_package_id,
          state: order_state,
          subState: order_sub_state,
        })

        return <Cell.OrderNumber id={order_id} name={order_name} number={order_number} href={pathToOpenOrder} />
      },
    },
    {
      Header: 'Project',
      id: 'project_name',
      width: 200,
      sortBy: 'project_name',
      disabled: ignoreProjectColumns,
      Cell: ({ row: { original: item }, column }) => (
        <PsqlTableCells.FormattedText
          column={column}
          value={`${item.project_identifier ? `${item.project_identifier}: ` : ''}${item.project_name}`}
        />
      ),
    },
    {
      Header: 'Vendor',
      id: 'vendor_name',
      accessor: 'company_vendor_name',
      sortBy: 'company_vendor_name',
      width: 250,
      Cell: ({
        row: {
          original: { order_is_draft, order_draft_vendor_names, company_vendor_name },
        },
      }) => (
        <Cell.VendorName
          isDraft={order_is_draft}
          draftVendorNames={order_draft_vendor_names}
          vendorName={company_vendor_name}
        />
      ),
    },
    {
      Header: 'Qty',
      id: 'quantity',
      accessor: 'quantity',
      sortBy: 'quantity',
      width: 120,
      Cell: OrderMaterialCells.Quantity,
    },
    {
      Header: 'Qty Delivered',
      id: 'quantity_delivered',
      accessor: 'quantity_delivered',
      sortBy: 'quantity_delivered',
      width: 200,
      Cell: OrderMaterialCells.Quantity,
    },
    {
      Header: 'UOM',
      id: 'company_material.unit',
      accessor: 'company_material_unit_name',
      sortBy: 'company_material_unit_name',
      width: 200,
      Cell: ({
        column,
        row: {
          original: {
            company_material_unit_name,
            company_material_unit_id,
            company_material_unit_name_with_increment_label,
          },
        },
      }) => {
        let value = null

        if (company_material_unit_id) {
          value = company_material_unit_name_with_increment_label
        }

        if (company_material_unit_name) {
          value = company_material_unit_name
        }

        return <PsqlTableCells.FormattedText column={column} value={value} />
      },
    },
    {
      Header: 'Unit Cost',
      id: 'unit_cost',
      accessor: 'unit_cost',
      sortBy: 'unit_cost',
      width: 200,
      format: 'currency',
      Cell: PsqlTableCells.FormattedText,
    },
    {
      Header: 'Ext. Cost',
      id: 'ext_cost',
      accessor: 'ext_cost',
      sortBy: 'ext_cost',
      width: 200,
      format: 'currency',
      Cell: PsqlTableCells.FormattedText,
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      sortBy: 'created_at',
      id: 'created_at',
      width: 200,
      format: 'date',
      Cell: PsqlTableCells.FormattedText,
    },
    {
      Header: 'Ordered By',
      id: 'order_ordered_by',
      sortBy: 'order_ordered_by',
      width: 250,
      Cell: ({
        row: {
          original: { order_state, order_ordered_by, order_is_quick_created },
        },
      }) => (
        <OrdersList.Cell.OrderedBy
          state={order_state}
          orderedBy={order_ordered_by}
          quickCreated={order_is_quick_created}
        />
      ),
    },
    {
      Header: 'Ordered At',
      id: 'order_ordered_at',
      accessor: 'order_ordered_at',
      sortBy: 'order_ordered_at',
      width: 200,
      format: 'date',
      Cell: PsqlTableCells.FormattedText,
    },
  ]

  companyAttributes.forEach((columnName: string) => {
    switch (columnName) {
      case 'cost_code_id':
        baseColumns.push({
          Header: 'Cost Code',
          id: 'cost_code.code',
          accessor: 'cost_code_code',
          sortBy: 'cost_code_code',
          width: 200,
          Cell: ({ row: { original: item } }) => (
            <OrderMaterialCells.CostCode
              phaseEnabled={costCodeColumn.phaseEnabled}
              classEnabled={costCodeColumn.classEnabled}
              code={item.cost_code_code}
              clazz={item.cost_code_class}
              phaseCode={item.cost_code_phase_code}
            />
          ),
          minWidth: 100,
        })
        break

      case 'cost_code_phase_id':
        baseColumns.push({
          Header: 'Phase Code',
          id: 'cost_code_phase_code',
          accessor: 'cost_code_phase_code',
          sortBy: 'cost_code_phase_code',
          Cell: ({ row: { original } }) => <OrderMaterialCells.PhaseCode code={original.cost_code_phase_code} />,
          minWidth: 100,
        })
        break

      case 'preferred_vendor_prices':
        baseColumns.push({
          Header: 'Preferred Vendors',
          accessor: 'company_material_preferred_vendor_prices',
          sortBy: 'company_material_preferred_vendor_prices',
          id: 'company_material.preferred_vendor_prices',
          width: 300,
          Cell: ({ row: { original } }) => (
            <OrderMaterialCells.PreferredVendors prices={original.company_material_preferred_vendor_prices} />
          ),
        })
        break

      // Default price and default vendor don't show here
      case 'project_ids':
      case 'unit':
      case 'description':
        break

      default:
        baseColumns.push({
          id: `company_material.${columnName}`,
          Header: formatEnumValue(columnName),
          width: 200,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          accessor: `company_material_${columnName}` as any,
          sortBy: `company_material_${columnName}`,
          Cell: PsqlTableCells.FormattedText,
        })
    }
  })

  baseColumns.push(
    {
      Header: 'Company Note',
      id: 'company_note',
      accessor: 'company_note',
      sortBy: 'company_note',
      width: 350,
      Cell: PsqlTableCells.FormattedText,
    },
    {
      Header: 'Vendor Note',
      id: 'vendor_note',
      sortBy: 'vendor_note',
      width: 350,
      Cell: ({
        row: {
          original: { vendor_note, vendor_response },
        },
      }) => <OrderMaterialCells.VendorNote vendorNote={vendor_note} vendorResponse={vendor_response} />,
    },
  )

  return baseColumns
}
