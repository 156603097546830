import React from 'react'

import { Typography, Upload } from 'antd'
import { UploadFile, UploadProps } from 'antd/lib/upload'

import { Box, FlexBoxX } from 'common/components/boxes'
import { FileControl } from 'common/components/uploader'
import theme from 'common/styles/theme'

import { CustomUploadItem } from '../../uploader/custom_upload_item'

type UploaderListProps = FileControl &
  Pick<UploadProps, 'onPreview' | 'fileList'> & {
    disabled?: boolean
    multiple: boolean
    onChange?: () => void
    onRemoveUpload?: (uploadKey: string, uid: string) => void
    title?: string
    tag?: React.ReactNode
    uploadKey: string
  }

export const UploaderList = ({
  disabled,
  multiple,
  onChange,
  title,
  fileList,
  uploadKey,
  onRemoveUpload,
  onPreview,
  tag,
}: UploaderListProps) => {
  const handlePreview = (file) => {
    window.open(file?.url)
    onPreview?.(file)
  }

  const handleRemove = onRemoveUpload ? (file: UploadFile) => onRemoveUpload(uploadKey, file.uid) : undefined

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" width="100%">
        <FlexBoxX display="flex" alignItems="start" width="100%">
          <Box display="flex" justifyContent="flex-start">
            {title && (
              <Typography.Text color={theme.colors['gray-8']} style={{ whiteSpace: 'nowrap', fontSize: 12 }}>
                {title}
              </Typography.Text>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%">
            {tag}
          </Box>
        </FlexBoxX>
      </Box>

      <Upload
        disabled={disabled}
        multiple={multiple}
        fileList={fileList}
        onChange={onChange}
        onRemove={handleRemove}
        onPreview={handlePreview}
        itemRender={(_, file) => (
          <CustomUploadItem
            size="small"
            file={file}
            color="gray-5"
            onDownload={handlePreview}
            onRemove={handleRemove}
          />
        )}
      />
    </Box>
  )
}
