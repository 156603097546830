import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { FloatActionsWrapper } from 'common/components/FloatActionsWrapper'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { MaterialRequestStateActions } from './material_request_state_actions'
import { OrderStateActions } from './order_state_actions'
import { QuotedStateActions } from './quoted_state_actions'

export type OrderActionsProps = {
  actionClicked: number
  onFinish: (params: {
    stateChanges?: { cancelled_at?: Nullable<string> }
    action?: number
    silentUpdate?: boolean
    isBack?: boolean
  }) => Promise<void>
  onPlaceOrder: () => Promise<void>
  onCancelOrder: () => Promise<void>
  isFloat?: boolean
  purchaseOrderModalVisible: boolean
  togglePurchaseOrderModalVisible: (visible: boolean) => void
  syncOrderWithIntegration: boolean
  toggleSyncOrderWithIntegration: (visible: boolean) => void
  isPoLocked?: boolean
  onRequestCancellation: (cancellationReason: string) => Promise<void>
  isSpreadsheetMode: boolean
  toggleSpreadsheetMode: () => void
}

export const OrderActions = observer<OrderActionsProps>(
  ({
    onFinish,
    onPlaceOrder,
    onCancelOrder,
    syncOrderWithIntegration,
    toggleSyncOrderWithIntegration,
    purchaseOrderModalVisible,
    togglePurchaseOrderModalVisible,
    actionClicked,
    isFloat,
    onRequestCancellation,
    isPoLocked,
    isSpreadsheetMode,
    toggleSpreadsheetMode,
  }) => {
    const { orderStore, userStore } = useStores()
    const { state } = orderStore.selectedOrder

    if (userStore.cannotSendAndUpdateOrders && userStore.cannotEditCostCode) {
      return null
    }

    const renderContent = () => {
      if (userStore.cannotSendAndUpdateOrders) {
        return (
          <MaterialRequestStateActions
            onClick={() => onFinish({ action: 2 })}
            loading={actionClicked === 3}
            disabled={isPoLocked || !orderStore.selectedOrderDirty}
          />
        )
      }

      if (state === OrderStates.QUOTED) {
        return (
          <QuotedStateActions
            onFinish={onFinish}
            onCancelOrder={onCancelOrder}
            onPlaceOrder={onPlaceOrder}
            actionClicked={actionClicked}
            isPoLocked={isPoLocked}
            purchaseOrderModalVisible={purchaseOrderModalVisible}
            togglePurchaseOrderModalVisible={togglePurchaseOrderModalVisible}
            syncOrderWithIntegration={syncOrderWithIntegration}
            toggleSyncOrderWithIntegration={toggleSyncOrderWithIntegration}
            onRequestCancellation={onRequestCancellation}
            isSpreadsheetMode={isSpreadsheetMode}
            toggleSpreadsheetMode={toggleSpreadsheetMode}
          />
        )
      }

      return (
        <OrderStateActions
          onFinish={onFinish}
          onCancelOrder={onCancelOrder}
          actionClicked={actionClicked}
          isPoLocked={isPoLocked}
          onRequestCancellation={onRequestCancellation}
          isSpreadsheetMode={isSpreadsheetMode}
          toggleSpreadsheetMode={toggleSpreadsheetMode}
        />
      )
    }

    const content = renderContent()

    if (isFloat) {
      return <FloatActionsWrapper bottom={64}>{content}</FloatActionsWrapper>
    }

    return (
      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        {content}
      </Box>
    )
  },
)
