import React, { useEffect, useRef, useState } from 'react'

import { set } from 'lodash'
import { v4 as uuid_v4 } from 'uuid'

import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import {
  QuantityColumnProps,
  TaxColumnProps,
  UnitColumnProps,
  UnitCostColumnProps,
  VendorNoteColumnProps,
  VendorResponseColumnProps,
} from 'common/components/OrderMaterialsV2/Columns'
import { List } from 'common/components/OrderMaterialsV2/List'
import { SelectCostCodeProps } from 'common/components/SelectCostCode'
import { SelectPhaseCodeProps } from 'common/components/SelectPhaseCode'
import { makeOption as makeUnitOption, Option as UnitOption } from 'common/components/SelectUnit'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { CompanyMaterialHit } from 'common/server/company_materials'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { OrderStates, VendorResponseType } from 'common/server/server_types'
import { Unit } from 'common/server/units'

import { usePopulateSameCostCode, UsePopulateSameCostCodePath } from 'contractor/hooks/use-populate-same-cost-code'
import { useStores } from 'contractor/hooks/use-stores'
import { PriceHistory } from 'contractor/pages/CompanyMaterials/Materials/PriceHistory'
import { UpdateMaterial } from 'contractor/pages/CompanyMaterials/Materials/UpdateMaterial'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'
import {
  CostCodeSettings,
  RequiredOrderFields,
  RequiredQuoteFields,
  RequiredRequestFields,
} from 'contractor/server/company_settings/other_settings'

import { Table } from './Table'

export type DataSource = {
  id?: string
  company_material: ShowCompanyMaterialResponse | CompanyMaterialHit
  quantity: number
  cost_code?: CostCode
  cost_code_id?: string
  cost_code_phase_id?: string
  unit_cost: string
  vendor_note?: string
  company_note?: string
  has_open_issue?: boolean
  quantity_delivered?: number
  unit?: UnitOption
  vendor_response?: VendorResponseType[]
  remaining_quantity?: number
  tax_value?: number
  changed_unit_cost_by_vendor?: number

  // use only internal the component to identify a unique row
  internal_id: string

  prev_quantity?: number
  prev_unit?: UnitOption
  default_cost_code_to_be_applied?: boolean
}

interface HandleMaybeApplyToAllProps {
  index: number
  value: CostCode | string
  path: string
}

export type OrderMaterialsProps = {
  isPoLocked?: boolean
  isSelecting?: boolean
  commitmentId?: string
  hideUnitCost?: boolean
  hideCostCode?: boolean
  isDraft?: boolean
  hideTax?: boolean
  hideVendorResponse?: boolean
  hideAddNewItem?: boolean
  onChange: (dataSource: DataSource[]) => void
  dataSource: Array<Omit<DataSource, 'internal_id'>>
  deliveryId?: string
  overBudgetMaterial?: string
  showDefaultVendorAlert?: boolean
  showDefaultVendor?: boolean
  showMaterialPriceDetails?: boolean
  disabled?: boolean
  costCodeDisabled?: boolean
  canCreateNewMaterial?: boolean
  canEditMaterialDatabase?: boolean
  canViewHistoricalPricing?: boolean
  showDeleteItem?: boolean
  roundingPrecision?: number
  unitCostInput?: UnitCostColumnProps
  unitInput?: UnitColumnProps
  costCodeInput?: SelectCostCodeProps
  phaseCodeInput?: SelectPhaseCodeProps
  quantityInput?: QuantityColumnProps
  vendorNoteInput?: VendorNoteColumnProps
  vendorResponseInput?: VendorResponseColumnProps
  taxInput?: TaxColumnProps
  editCompanyNote?: boolean
  showDeliveryIssues?: boolean
  isRequest?: boolean

  costCodeSettings?: CostCodeSettings
  requiredFields?: {
    order?: RequiredOrderFields
    quote?: RequiredQuoteFields
    request?: RequiredRequestFields
  }

  publicOrderFormUrlExtension?: string
  companyAttributes?: string[]
  units?: Unit[]

  projectId?: string
  orderType: OrderType
  companyVendorId?: string

  userId?: string
  orderState?: OrderStates
}

const getInternalId = (data, index) => `${data.company_material?.id}:${index}`

export const getUnit = (companyMaterial): UnitOption => {
  if (companyMaterial?.unit_id) {
    return makeUnitOption(companyMaterial?.unit)
  }

  if (companyMaterial?.unit_name) {
    return {
      value: companyMaterial?.unit_name,
      label: companyMaterial?.unit_name,
      selectedLabel: companyMaterial?.unit_name,
      original: null,
    }
  }
}

const isCatalogMaterial = (companyMaterial) => companyMaterial?.company_id === 'CATALOG'

export const OrderMaterials = observer<OrderMaterialsProps>((props) => {
  const { companyMaterialStore, userStore } = useStores()

  const {
    onChange,
    deliveryId,
    dataSource: dataSourceProp = [],
    isSelecting,
    disabled,
    showDefaultVendor,
    showDefaultVendorAlert,
    canCreateNewMaterial,
    canEditMaterialDatabase,
    canViewHistoricalPricing,
    publicOrderFormUrlExtension,
    companyAttributes,
    units,
    projectId,
    orderType,
    companyVendorId,
    requiredFields,
    costCodeSettings,
    userId,
    orderState,
    showDeliveryIssues,
    showDeleteItem = true,
    commitmentId,
    isDraft,
    overBudgetMaterial,
    isRequest,
    costCodeDisabled,
  } = props

  const {
    hideUnitCost,
    hideCostCode,
    hideVendorResponse,
    hideAddNewItem,
    hideTax = true,
    showMaterialPriceDetails,
  } = props

  const {
    unitInput,
    costCodeInput,
    phaseCodeInput,
    unitCostInput,
    quantityInput,
    vendorResponseInput,
    taxInput,
    editCompanyNote = true,
    roundingPrecision,
  } = props

  const dataSource = dataSourceProp
    .map((data) => ({ ...data, unit: data?.unit || getUnit(data?.company_material) }))
    .map((data, index) => ({ ...data, internal_id: getInternalId(data, index) }))
    .map((data) => ({
      ...data,
      company_material: {
        ...data?.company_material,
        internal_id: uuid_v4(),
      },
    })) as Array<DataSource>

  const isMobileScreen = useMediaQuery('md')

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isReplacingMaterial, toggleReplacingMaterial] = useState({})
  const isMaterialRequester = userStore.cannotSendAndUpdateOrders

  const populateSameCostCode = usePopulateSameCostCode<DataSource>({
    datasource: dataSource,
    itemIdentifierKey: 'company_material.id',
  })

  const drawerPriceHistoryRef = useRef<DrawerRef>()
  const drawerUpdateRef = useRef<DrawerRef>()

  const beforeOnChange = (changes = []) => {
    onChange(changes)
  }

  const scollToLastSelectedMaterial = () => {
    const mobileRows = document.getElementsByClassName('row-item')
    const desktopRows = document.getElementsByClassName('ant-table-row')
    mobileRows[mobileRows.length]?.scrollIntoView({ behavior: 'smooth' })
    desktopRows[desktopRows.length]?.scrollIntoView({ behavior: 'smooth' })
  }

  const setFocusOnInputQuantityLastSelectedMaterial = () => {
    const inputsQuantity = document.querySelectorAll(
      '.input-quantity .ant-input-number-input',
    ) as NodeListOf<HTMLInputElement>

    const inputQuantity = isMobileScreen
      ? inputsQuantity[inputsQuantity.length]
      : inputsQuantity[inputsQuantity.length - 1]
    inputQuantity?.focus()
  }

  const handleCostCodeSelect = (companyMaterial: ShowCompanyMaterialResponse | CompanyMaterialHit) => {
    const costCodeProjects = companyMaterial?.cost_code?.project_ids
    if (costCodeSettings?.project_filtering_enabled && !!costCodeProjects?.length) {
      return costCodeProjects?.includes(projectId) ? companyMaterial.cost_code : null
    }

    return companyMaterial?.cost_code
  }

  const handleCostCodePhaseSelect = (companyMaterial: ShowCompanyMaterialResponse | CompanyMaterialHit) => {
    const costCodePhaseProjects = companyMaterial?.cost_code?.project_ids
    if (costCodeSettings?.project_specific_phase_codes_enabled && !!costCodePhaseProjects?.length) {
      return costCodePhaseProjects?.includes(projectId) ? companyMaterial.cost_code_phase : null
    }

    return companyMaterial?.cost_code_phase
  }

  // Use the matched companyVendorId with preferredVendorPrice or use the lowest price as default
  const getPreferredVendorPrice = (companyMaterial: ShowCompanyMaterialResponse | CompanyMaterialHit) => {
    if (orderType === 'RFQ') {
      // If it's a rfq draft or the user set the requests to be rfq we should not populate pricing
      // otherwise we should fill it in
      return null
    }

    const preferredVendorPrices =
      companyMaterial['company_material_vendor_prices'] || companyMaterial['preferred_vendor_prices']

    if (companyVendorId && preferredVendorPrices?.length) {
      const matchedDefaultVendor = preferredVendorPrices.find((defaultVendor) => {
        return defaultVendor.company_vendor.id === companyVendorId
      })

      return matchedDefaultVendor
    } else if (preferredVendorPrices?.length) {
      const itemWithLowestPrice = preferredVendorPrices.reduce((minItem, currentItem) => {
        return Number(currentItem.price) < Number(minItem.price) ? currentItem : minItem
      }, preferredVendorPrices[0])

      return itemWithLowestPrice
    }
  }

  const handleSelectMaterial = (companyMaterial: ShowCompanyMaterialResponse | CompanyMaterialHit) => {
    const defaultCostCode = handleCostCodeSelect(companyMaterial)
    const defaultCostCodePhase = handleCostCodePhaseSelect(companyMaterial)

    if (!defaultCostCode && populateSameCostCode.defaultValues.cost_code) {
      populateSameCostCode.addToAppliedByAll('cost_code', companyMaterial.id)
    }

    if (!defaultCostCodePhase && populateSameCostCode.defaultValues.cost_code_phase_id) {
      populateSameCostCode.addToAppliedByAll('cost_code_phase_id', companyMaterial.id)
    }

    const preferredVendorPrice = getPreferredVendorPrice(companyMaterial)
    if (preferredVendorPrice) {
      unitCostInput?.onChange?.(preferredVendorPrice?.price, { defaultVendor: preferredVendorPrice })
    }

    const material = {
      delivery_id: deliveryId,
      company_material: companyMaterial,
      unit_cost: companyMaterial?.unit_price || preferredVendorPrice?.price,
      remaining_quantity: companyMaterial?.remaining_quantity,
      unit: getUnit(companyMaterial),
      cost_code: defaultCostCode || populateSameCostCode.defaultValues.cost_code,
      cost_code_phase_id:
        defaultCostCodePhase?.id ||
        companyMaterial['cost_code_phase_id'] ||
        populateSameCostCode.defaultValues.cost_code_phase_id,
      quantity: null,
      vendor_note: null,
      preferred_vendor_price: preferredVendorPrice,
    }

    beforeOnChange([...dataSource, material])

    setTimeout(() => {
      setFocusOnInputQuantityLastSelectedMaterial()
      scollToLastSelectedMaterial()
    }, 100)
  }

  const handleRemoveMaterial = (removeIndex: number) => {
    const changes = dataSource.filter((_, index) => index !== removeIndex)
    beforeOnChange(changes)
  }

  const handleAfterUpdateCompanyMaterial = (
    companyMaterial: ShowCompanyMaterialResponse,
    originalMaterial: ShowCompanyMaterialResponse,
  ) => {
    const cost_code_default_has_changed = originalMaterial?.cost_code?.id !== companyMaterial.cost_code?.id
    const changes = dataSource.map((data) => {
      const cost_code = cost_code_default_has_changed ? companyMaterial.cost_code : data?.cost_code
      if (
        (companyMaterial?.id && data.company_material?.id === companyMaterial?.id) ||
        (!companyMaterial?.id && data.company_material?.['internal_id'] === companyMaterial?.['internal_id'])
      ) {
        return {
          ...data,
          company_material: {
            ...data.company_material,
            ...companyMaterial,
            id: companyMaterial?.['is_new'] ? null : companyMaterial?.id,
          },
          unit: companyMaterial?.unit,
          cost_code,
        }
      }

      return data
    })

    beforeOnChange(changes)
    drawerUpdateRef.current?.close()
  }

  function handleMaybeApplyToAll(props: HandleMaybeApplyToAllProps) {
    const { index, value, path } = props

    const changes = populateSameCostCode.handleBulkChange({
      checked: true,
      triggerIndex: index,
      path: path as UsePopulateSameCostCodePath,
      value,
    })

    beforeOnChange(changes)
  }

  const handleChangeDataWithIndex = (indexChanged: number, path: string, value: unknown, shouldApplyToAll = false) => {
    const changes = dataSource.map((data, index) => {
      if (index === indexChanged) {
        set(data, path, value)

        if (
          populateSameCostCode.hasItemBeenChangedByAppliedToAll(
            path as UsePopulateSameCostCodePath,
            data.company_material.id,
          )
        ) {
          populateSameCostCode.removedByAppliedToAll(path as UsePopulateSameCostCodePath, data.company_material.id)
        }
      }

      if (shouldApplyToAll) {
        data = populateSameCostCode.handleChange({
          item: data,
          triggerItem: index === indexChanged,
          path: path as UsePopulateSameCostCodePath,
          value,
        })
      }

      return data
    })

    beforeOnChange(changes)
  }

  const handleChangeDataUnitWithIndex = (indexChanged: string | number, value: unknown) => {
    const changes = dataSource.map((data, index) => {
      if (index === indexChanged) {
        set(data, 'unit', value)
        // If the user changes the unit with pending approval material, we accept it as approved
        set(data, 'company_material.requested_at', null)
        set(data, 'company_material.requested_by_id', null)
      }
      return data
    })

    beforeOnChange(changes)
  }

  const handleSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)

    const changes = dataSource.map((data, index) => {
      const internalId = getInternalId(data, index)
      if (newSelectedRowKeys.includes(internalId)) {
        return {
          ...data,
          select: true,
        }
      } else {
        return {
          ...data,
          select: false,
        }
      }
    })

    beforeOnChange(changes)
  }

  const handleToggleReplaceCompanyMaterial = (companyMaterialIndex: number) => {
    if (isReplacingMaterial[companyMaterialIndex]) {
      toggleReplacingMaterial((prevState) => ({ ...prevState, [companyMaterialIndex]: false }))
    } else {
      toggleReplacingMaterial((prevState) => ({ ...prevState, [companyMaterialIndex]: true }))
    }
  }

  const handleTogglePriceHistory = (companyMaterial: ShowCompanyMaterialResponse) => {
    if (companyMaterial?.id && !isCatalogMaterial(companyMaterial)) {
      companyMaterialStore.selectMaterial(companyMaterial?.id).then(() => drawerPriceHistoryRef.current?.show())
    } else {
      companyMaterialStore.replaceSelectedMaterial({ ...companyMaterial, id: null, active: true })
      drawerPriceHistoryRef.current?.show()
    }
  }

  const handleReplaceCompanyMaterial = (
    newCompanyMaterial: DataSource['company_material'],
    prevCompanyMaterialIndex: number,
  ) => {
    const changes = dataSource.map((data, index) => {
      if (index === prevCompanyMaterialIndex) {
        return {
          ...data,
          company_material: newCompanyMaterial,
          unit: getUnit(newCompanyMaterial),
        }
      }

      return data
    })

    toggleReplacingMaterial((prevState) => ({ ...prevState, [prevCompanyMaterialIndex]: false }))
    beforeOnChange(changes)
  }

  const handleEditCompanyMaterial = (companyMaterial) => {
    if (companyMaterial?.id && !isCatalogMaterial(companyMaterial)) {
      companyMaterialStore.selectMaterial(companyMaterial?.id).then(() => drawerUpdateRef.current?.show())
    } else {
      companyMaterialStore.replaceSelectedMaterial({ ...companyMaterial, id: null, active: true })
      drawerUpdateRef.current?.show()
    }
  }

  const handleChangeVendorResponse = ({ index, value, row }) => {
    if (
      value.includes(VendorResponseType.CHANGED_UNITS) &&
      !row?.vendor_response?.includes(VendorResponseType.CHANGED_UNITS)
    ) {
      !row?.prev_unit && handleChangeDataWithIndex(index, 'prev_unit', row?.unit)
      !row?.prev_quantity && handleChangeDataWithIndex(index, 'prev_quantity', row?.quantity)

      handleChangeDataWithIndex(index, 'unit', '')
      handleChangeDataWithIndex(index, 'quantity', 0)
    } else if (
      !value.includes(VendorResponseType.CHANGED_UNITS) &&
      row?.vendor_response?.includes(VendorResponseType.CHANGED_UNITS)
    ) {
      handleChangeDataWithIndex(index, 'unit', row?.prev_unit)
      handleChangeDataWithIndex(index, 'quantity', row?.prev_quantity)
    }

    handleChangeDataWithIndex(index, 'vendor_response', value)
  }

  useEffect(() => {
    !isSelecting && setSelectedRowKeys([])
  }, [isSelecting])

  const materialsAutocompleteProps = {
    onSelect: handleSelectMaterial,
    disabled,
    publicOrderFormUrlExtension,
    companyAttributes,
    units,
    projectId,
    canCreateNewMaterial,
    userId,
    orderState,
    commitmentId,
  }

  const commonProps = {
    dataSource,
    disabled,
    canEditMaterialDatabase,
    canViewHistoricalPricing,
    editCompanyNote,
    orderType,
    isRequest,
    requiredFields,
    costCodeSettings,
    materialsAutocompleteProps,
    hideVendorResponse,
    hideCostCode,
    showDeleteItem,
    showDeliveryIssues,
    hideUnitCost,
    hideAddNewItem,
    hideTax,
    vendorResponseInput,
    roundingPrecision,
    commitmentId,
    isDraft,
    overBudgetMaterial,
    phaseCodeInput: {
      ...phaseCodeInput,
      disabled: phaseCodeInput?.disabled || costCodeDisabled,
      onChangeApplyToAll: handleMaybeApplyToAll,
    },
    costCodeInput: {
      ...costCodeInput,
      disabled: costCodeInput?.disabled || costCodeDisabled,
      onChangeApplyToAll: handleMaybeApplyToAll,
    },
    unitCostInput: {
      ...unitCostInput,
      disabled: unitCostInput?.disabled || disabled,
    },
    unitInput: {
      ...unitInput,
      disabled: unitInput?.disabled || disabled,
    },
    quantityInput: {
      ...quantityInput,
      disabled: quantityInput?.disabled || disabled,
    },
    taxInput: {
      ...taxInput,
      disabled: taxInput?.disabled || disabled,
    },
    showMaterialPriceDetails,
    showDefaultVendorAlert,
    showDefaultVendor,
    orderState,
    isReplacingMaterial,
    isMaterialRequester,
    onApproveCompanyMaterial: handleEditCompanyMaterial,
    onReplaceCompanyMaterial: handleReplaceCompanyMaterial,
    onToggleReplaceCompanyMaterial: handleToggleReplaceCompanyMaterial,
    onTogglePriceHistoryCompanyMaterial: handleTogglePriceHistory,
    onEditCompanyMaterial: handleEditCompanyMaterial,
    onRemoveMaterial: handleRemoveMaterial,
    onChangeDataWithIndex: handleChangeDataWithIndex,
    onChangeDataUnitWithIndex: handleChangeDataUnitWithIndex,
    onChangeVendorResponse: handleChangeVendorResponse,
  }

  if (isMobileScreen) {
    return (
      <>
        <List {...commonProps} companyVendorId={companyVendorId} />
        {canEditMaterialDatabase && (
          <UpdateMaterial
            hideNotification
            editable
            onSubmit={handleAfterUpdateCompanyMaterial}
            onCancel={() => drawerUpdateRef.current.close()}
            scope="OrderPage"
            ref={drawerUpdateRef}
          />
        )}
        {canViewHistoricalPricing && (
          <PriceHistory
            hideNotification
            editable={canEditMaterialDatabase}
            onCancel={() => drawerPriceHistoryRef.current.close()}
            ref={drawerPriceHistoryRef}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Table
        {...commonProps}
        companyVendorId={companyVendorId}
        isSelecting={isSelecting}
        rowSelection={{
          selectedRowKeys,
          onChange: handleSelectChange,
        }}
      />
      {canEditMaterialDatabase && (
        <UpdateMaterial
          hideNotification
          editable
          onSubmit={handleAfterUpdateCompanyMaterial}
          onCancel={() => drawerUpdateRef.current.close()}
          scope="OrderPage"
          ref={drawerUpdateRef}
        />
      )}
      {canViewHistoricalPricing && (
        <PriceHistory
          hideNotification
          editable={canEditMaterialDatabase}
          onCancel={() => drawerPriceHistoryRef.current.close()}
          ref={drawerPriceHistoryRef}
        />
      )}
    </>
  )
})
