import React from 'react'

import { observer } from 'mobx-react-lite'

import { usePsqlTable } from 'common/components/PsqlTable/psql_table_provider'
import { formatDateStringShort } from 'common/helpers/formatters'

import { DownloadButton } from 'contractor/components/DownloadButton'
import { useStores } from 'contractor/hooks/use-stores'

// Extracts POs and Order Names from the order string
function extractPosAndNames(inputString) {
  const pos = []
  const orderNames = []

  const regex = /([^:,]+):\s*([^,]+)/g
  let match

  while ((match = regex.exec(inputString)) !== null) {
    pos.push(match[1])
    orderNames.push(match[2].trim())
  }

  return {
    pos: pos.join(', '),
    orderNames: orderNames.join(', '),
  }
}

const insertIf = (condition, ...elements) => (condition ? elements : [])

export const Download = observer(() => {
  const { invoiceStore, invoiceSettingsStore } = useStores()
  const { state } = usePsqlTable()
  const accountingDateEnabled = invoiceSettingsStore?.settings?.accounting_date_enabled

  const headers = [
    { label: 'Status', key: 'state_label' },
    { label: 'Assigned To', key: 'assigned_to_label' },
    { label: 'Vendor', key: 'company_vendor_name' },
    { label: 'Invoice Number', key: 'invoice_number' },
    { label: 'Projects', key: 'project_names' },
    { label: 'PO', key: 'pos', groupedKey: 'order_names' },
    { label: 'Order Names', key: 'order_names' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Invoice Date', key: 'invoice_date' },
    { label: 'Due Date', key: 'due_date' },
    { label: 'Total Amount', key: 'total_amount' },
    { label: 'Posted At', key: 'posted_at' },
    { label: 'Tags', key: 'tags' },
    ...insertIf(accountingDateEnabled, { label: 'Accounting Date', key: 'accounting_date' }),
  ].filter((header) => !state.hiddenColumns.includes(header.key) && !state.hiddenColumns.includes(header.groupedKey))

  const dataMapper = (invoice) => {
    const orders = extractPosAndNames(invoice['order_names'])

    return {
      ...invoice,
      pos: orders.pos,
      order_names: orders.orderNames,
      tags: invoice['tags']?.join(', '),
      invoice_date: formatDateStringShort(invoice['invoice_date']),
      due_date: formatDateStringShort(invoice['due_date']),
      posted_at: formatDateStringShort(invoice['posted_at']),
      created_at: formatDateStringShort(invoice['created_at']),
      state_label: invoice['state']?.['label'],
      accounting_date: formatDateStringShort(invoice['accounting_date']),
    }
  }

  return (
    <DownloadButton
      onLoadData={invoiceStore.listStore.fetchAllRecords}
      headers={headers}
      dataMapper={dataMapper}
      title="Invoices"
      filename="invoices"
    />
  )
})
