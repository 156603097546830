import React, { createContext, useContext, useEffect, useState } from 'react'

import { Form } from 'antd'
import { FormInstance } from 'antd/lib/form/hooks/useForm'

import { Loading } from 'common/components/Loading'
import * as newrelic from 'common/helpers/new_relic'
import { useQuery } from 'common/hooks/use-query'
import { initializePendo } from 'common/pendo/initialize_pendo'

import { useStores } from 'contractor/hooks/use-stores'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'
import { Project } from 'contractor/server/public_order_form'

const PublicOrderFormContext = createContext<PublicOrderFormContextProps>({} as PublicOrderFormContextProps)

type PublicOrderFormContextProps = {
  isSubmitting: boolean
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  user?: ManagedUser
  setUser: React.Dispatch<React.SetStateAction<ManagedUser>>
  project?: Project
  setProject: React.Dispatch<React.SetStateAction<Project>>
  form: FormInstance
  redirectToUserRegistration: () => void
}

type PublicOrderFormProviderProps = {
  children: React.ReactNode
}

export const usePublicOrderForm = () => useContext(PublicOrderFormContext)

export const PublicOrderFormProvider = ({ children }: PublicOrderFormProviderProps) => {
  const { publicOrderStore } = useStores()

  const { formParams } = publicOrderStore

  const getSearchParams = (): URLSearchParams =>
    new URLSearchParams({
      public_order_form_url_extension: formParams?.public_order_form_url_extension,
    })

  const [isSubmitting, setSubmitting] = useState(false)
  const [user, setUser] = useState<ManagedUser>(undefined)
  const [project, setProject] = useState<Project>(undefined)
  const [params, setParams] = useState(getSearchParams())

  const [form] = Form.useForm()

  useQuery(publicOrderStore.resetOrderRequest)
  const { isLoading } = useQuery(() => publicOrderStore.index())

  const redirectToUserRegistration = () => {
    params.set('first_name', form.getFieldValue('first_name') || '')
    params.set('last_name', form.getFieldValue('last_name') || '')
    params.set('email', form.getFieldValue('email') || '')
    window.location.href = `/?${params.toString()}`
  }

  useEffect(() => {
    if (formParams) setParams(getSearchParams())
  }, [formParams])

  useEffect(() => {
    if (!!formParams && !!user) {
      initializePendo({
        id: user?.id,
        email: user?.email,
        role: user?.role,
        type: 'CONTRACTOR',
        account: {
          id: formParams.company_id,
          name: formParams.company_name,
        },
      })
    }
  }, [formParams, user])

  useEffect(() => {
    if (formParams?.company_id) {
      newrelic.setUserInfo({
        companyId: formParams.company_id,
        companyName: formParams.company_name,
        type: 'CONTRACTOR',
        public: true,
      })
    }
  }, [formParams?.company_id])

  if (!publicOrderStore.formParams) {
    return null
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <PublicOrderFormContext.Provider
      value={{
        isSubmitting,
        setSubmitting,
        user,
        setUser,
        project,
        setProject,
        form,
        redirectToUserRegistration,
      }}
    >
      {children}
    </PublicOrderFormContext.Provider>
  )
}
