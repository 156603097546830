import React, { useEffect, useMemo, useRef, useState } from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import styled from '@emotion/styled'

import { Input, message, Modal, notification, Space, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { PdfViewer } from 'common/components/PdfViewer'
import { TextArea } from 'common/components/TextArea'
import UnloadWarning from 'common/components/unload_warning'
import { Visibility } from 'common/components/Visibility'
import { currencyFormatter } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'
import { InvoicesStates, InvoicesStatuses, RelationshipStockStatus } from 'common/server/server_types'

import { useFlag } from 'contractor/hooks/use-flag'
import { useLockInvoice } from 'contractor/hooks/use-lock-invoice'
import { useStores } from 'contractor/hooks/use-stores'
import { InvoiceCorrelateModal } from 'contractor/pages/Invoices/Detail/CorrelatorModal/modal'
import { AccountingDate } from 'contractor/pages/Invoices/Detail/Header/accounting_date'
import { WarnNotices } from 'contractor/pages/Invoices/Detail/Header/warn_notices'
import { InvoiceCorrelatingOverlay } from 'contractor/pages/Invoices/Detail/invoice_correlating_overlay'
import { InvoiceDiscardDrawer } from 'contractor/pages/Invoices/Detail/invoice_discard_drawer'
import { InvoiceGone } from 'contractor/pages/Invoices/Detail/invoice_gone'
import { InvoiceProject } from 'contractor/pages/Invoices/Detail/invoice_project'
import { CreateInvoice } from 'contractor/server/integrations/invoices'

import { CompanyVendorSelector } from './company_vendor_selector'
import { useInvoice } from './context'
import { ExtractedStep } from './extracted_step'
import { Footer } from './Footer'
import { FloatActions, Header } from './Header'
import { DocumentDate } from './Header/document_date'
import { InvoiceNumber } from './Header/invoice_number'
import { IntegrationSync } from './integration_sync'
import { ReconcileStep } from './reconcile_step'
import { ReviewStep } from './ReviewStep'

const SAVE_AND_NEXT_INVOICE_KEY = 'saveAndNextInvoice'

const getDefaultAction = () => JSON.parse(localStorage.getItem(SAVE_AND_NEXT_INVOICE_KEY))

export const insertIf = (condition, ...elements) => (condition ? elements : [])

const PageContent = styled(Page.Content)`
  position: relative;
  gap: 8px;
  .ant-table .ant-table-tbody .ant-table-row {
    height: 50px;
  }
`

export const InvoiceDetailExtract = observer(() => {
  const {
    invoiceStore,
    invoiceStateStore,
    integrationStore,
    userStore,
    companySettingStore,
    costCodeStore,
    invoiceSettingsStore,
  } = useStores()

  const { form, selectedInvoiceDirty, setSelectedInvoiceDirty, calculatedMobileGrandTotal } = useInvoice()

  const { params } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const lastLocation = useLastLocation()

  const usingConsolidatedInvoice = useFlag('consolidated_invoices')

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
  const accountingDateEnabled = invoiceSettingsStore?.settings?.accounting_date_enabled

  const drawerRef = useRef<DrawerRef>()

  const { invoice } = invoiceStore
  const { listStore } = invoiceStore

  const listItems = invoiceStore.hits.length > 0 ? [...invoiceStore.hits] : listStore.records

  const [saveAndNextDefault, setSaveAndNextDefault] = useState(getDefaultAction())
  const [previousListLocation] = useState(lastLocation)
  const isInvoiceLocked = useLockInvoice(invoiceStore?.invoice)

  const [openIssueModal, setOpenIssueModal] = useState(false)
  const [issueMessage, setIssueMessage] = useState('')

  const [attachedOrderIds, setAttachedOrderIds] = useState([])
  const [stateId, setStateId] = useState('')
  const [assignToId, setAssignToId] = useState(null)
  const [rejectionReason, setRejectionReason] = useState('')

  const [rejectionReasonOpen, toggleRejectionReasonModal] = useState(false)
  const [integrationModalOpen, toggleIntegrationModal] = useState(false)

  const [isSubmitting, setSubmitting] = useState(false)

  const rejectedState = invoiceStateStore.invoiceStates?.find(({ state }) => state === InvoicesStates.REJECTED)
  const postedState = invoiceStateStore.invoiceStates?.find(({ state }) => state === InvoicesStates.POSTED)

  const [showCorrelateModal, setShowCorrelateModal] = useState(false)
  const [isCorrelating, setIsCorrellating] = useState(false)
  const [isReprocessing, setIsReprocessing] = useState(false)

  useEffect(() => {
    if (invoiceStore.invoice?.status === InvoicesStatuses.CORRELATING_ITEMS) {
      setIsCorrellating(true)
    } else {
      setIsCorrellating(false)
      setShowCorrelateModal(false)
    }
  }, [invoiceStore.invoice?.status])

  const { isLoading: isLoadingOrders } = useQuery(() => {
    return Promise.all(attachedOrderIds.map((orderId) => invoiceStore.selectOrder(orderId, true)))
  }, [attachedOrderIds])

  useQuery(() => {
    if (costCodeSettings?.independent_phase_codes_enabled) {
      return costCodeStore.fetchAllActivePhaseCodes()
    }
  }, [costCodeSettings?.independent_phase_codes_enabled])

  useQuery(invoiceStore.getInvoiceTags)
  useQuery(costCodeStore.fetchAllActiveCostCodes)
  useQuery(() => {
    if (userStore.canUseIntegrations) {
      return integrationStore.accountingInfo()
    }
  })
  const { isLoading: isLoadingCompanyMaterialConfiguration } = useQuery(
    companySettingStore.indexCompanyMaterialConfiguration,
  )
  const { isLoading: isLoadingCompanySettings } = useQuery(invoiceSettingsStore.indexSettings)
  const { isLoading } = useQuery(() => {
    invoiceStore.invoiceSubscribe(params['id'], userStore.currentUser?.id)
    invoiceStore
      .getInvoice(params['id'])
      .then((invoice) => {
        setAttachedOrderIds(invoice.orders?.map((order) => order.id))
        setAssignToId(invoice.assigned_to_id)
        setStateId(invoice.state?.id)
        setRejectionReason(invoice.rejection_reason)
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          history.push('/invoices')
        } else if (error?.response?.status === 410) {
          setIsReprocessing(true)
        }
      })
  }, [params['id']])

  const goBack = () => {
    if (previousListLocation) {
      history.push(previousListLocation)
    } else {
      history.push('/invoices')
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if (params.get('retry-sync') === 'true') {
      toggleIntegrationModal(true)
    }
  }, [location.search])

  useEffect(() => {
    return () => {
      invoiceStore.getInvoice(null)
    }
  }, [])

  const handleNavigateAfterSave = async () => {
    const currentInvoiceIndex = listItems?.findIndex(
      (listItem) => listItem.invoice_id === invoiceStore.invoice.id || listItem.id === invoiceStore.invoice.id,
    )
    invoiceStore.selectedOrders.replace([])
    invoiceStore.selectedOrdersMaterials.replace([])

    if (currentInvoiceIndex < listItems.length - 1) {
      const nextInvoice = listItems[currentInvoiceIndex + 1]

      history.push(`./${nextInvoice?.['invoice_id'] || nextInvoice?.id}`)
    } else {
      goBack()
    }
  }

  const handleSendIssue = async () => {
    try {
      await invoiceStore.reportIssue(invoice.id, { issue_message: issueMessage })
      notification.success({
        message: 'Thanks for your issue report!\n Our team will take care of it ASAP.',
        placement: 'bottomLeft',
      })
      setIssueMessage('')
    } catch {
      notification.error({
        message: 'Error while sending the issue, try again later',
        placement: 'bottomLeft',
      })
    } finally {
      setOpenIssueModal(false)
    }
  }

  // Show modal to the user select sync or not the invoice. Only show the modal if it is not synced yet, or the previous sync has failed
  const maybeShowIntegrationBeforePost = () => {
    const statusAllowToSync = [RelationshipStockStatus.NOT_SYNCED, RelationshipStockStatus.FAILED]

    // Show modal to the user select sync or not the invoice. Only show the modal if it is not synced yet, or the previous sync has failed
    if (
      stateId === postedState.id &&
      !integrationModalOpen &&
      statusAllowToSync.includes(invoiceStore.invoice?.integration?.status) &&
      integrationStore.connected &&
      userStore.canUseIntegrations &&
      integrationStore.invoiceSyncEnabled &&
      userStore.canPostInvoices
    ) {
      toggleIntegrationModal(true)
      return true
    }

    return false
  }

  // Show modal to the user fill in the reject reason when changing to reject state
  const maybeShowRejectionModalBeforeReject = () => {
    if (stateId === rejectedState.id && !rejectionReasonOpen) {
      toggleRejectionReasonModal(true)
      return true
    }

    return false
  }

  const saveInvoice = async (autoMatch = false, orderIds = []) => {
    const formValues = await form.validateFields().catch((error) => {
      history.push({ search: new URLSearchParams({ step: '0' }).toString() })
      throw error
    })

    return invoiceStore.save(
      {
        order_ids: [...attachedOrderIds, ...orderIds],
        state_id: stateId,
        company_vendor_id: invoice.company_vendor_id || null,
        assigned_to_id: assignToId,
        watcher_ids: invoice.watcher_ids,
        tags: invoice.tags,
        rejection_reason: stateId === rejectedState.id ? rejectionReason?.trim() : null,
        name: invoice?.name,
        number: invoice?.number,
        document_date: invoice?.document_date,
        total_amount: formValues?.totalAmount,
        subtotal_amount: formValues?.subtotalAmount,
        tax_amount: formValues?.taxAmount,
        shipping_cost: formValues?.shippingCost,
        discount_amount: formValues?.discountAmount,
        other_costs: formValues?.otherCosts,
        project_id: invoice?.project?.id || null,
        accounting_date: invoice?.accounting_date,
        invoice_materials: invoice.invoice_materials.map((invoiceMaterial) => ({
          ...invoiceMaterial,
          id: invoiceMaterial?.id?.startsWith('new-item-') ? null : invoiceMaterial?.id,
          order_material_ids: invoiceMaterial?.order_materials?.map((orderMaterial) => orderMaterial.id),
          cost_code_id: invoiceMaterial?.cost_code?.id || null,
        })),
      },
      autoMatch,
    )
  }

  const handleConfirmSync = async (formValues) => {
    try {
      setSubmitting(true)
      toggleIntegrationModal(false)
      await saveInvoice()

      const { vendor, customer } = formValues
      let invoiceParams: CreateInvoice = { id: invoice?.id }
      if (!integrationStore.isProcore()) {
        invoiceParams = {
          ...invoiceParams,
          vendor_id: vendor?.value || vendor,
          customer_id: customer?.value || customer,
        }
      }

      if (integrationStore.isAcumatica()) {
        await integrationStore.createInvoiceThrowError(invoiceParams)
      } else {
        await integrationStore.createInvoice(invoiceParams)
      }

      await invoiceStore.getInvoice(params['id'])

      if (saveAndNextDefault) {
        handleNavigateAfterSave()
      } else if (integrationStore.isAcumatica()) {
        goBack()
      }

      notification.success({
        message: `Syncing the invoice with ${integrationStore.getIntegrationName()}`,
        description: 'The synchronization can take a time to finish.',
        placement: 'bottomLeft',
        duration: 10,
      })
    } catch (error) {
      message.error(error?.response?.data?.message || 'Unable to sync.')
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveInvoice = async () => {
    saveAndNextDefault && setSaveAndNextDefault(false)
    localStorage.setItem(SAVE_AND_NEXT_INVOICE_KEY, 'false')

    const showIntegrationModal = maybeShowIntegrationBeforePost()
    const showRejectionModal = maybeShowRejectionModalBeforeReject()

    if (showRejectionModal) {
      return
    }

    try {
      setSubmitting(true)
      await saveInvoice()
      setSelectedInvoiceDirty(false)

      message.success('Invoice updated')

      if (!showIntegrationModal) {
        goBack()
      }
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to save the invoice')
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveInvoiceAndLeave = async () => {
    try {
      setSubmitting(true)
      await saveInvoice()
      setSelectedInvoiceDirty(false)

      notification.success({
        message: 'Invoice updated',
        description: 'Please allow a few seconds for your changes to appear',
        placement: 'bottomLeft',
        duration: 10,
      })
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to save the invoice')
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveInvoiceAndNext = async () => {
    !saveAndNextDefault && setSaveAndNextDefault(true)
    localStorage.setItem(SAVE_AND_NEXT_INVOICE_KEY, 'true')

    const showIntegrationModal = maybeShowIntegrationBeforePost()
    const showRejectionModal = maybeShowRejectionModalBeforeReject()

    if (showIntegrationModal || showRejectionModal) {
      return
    }

    try {
      setSubmitting(true)
      await saveInvoice()
      setSelectedInvoiceDirty(false)

      if (usingConsolidatedInvoice) {
        message.success('Invoice updated')

        handleNavigateAfterSave()

        return
      }

      notification.success({
        message: 'Invoice updated',
        description: 'Please allow a few seconds for your changes to appear',
        placement: 'bottomLeft',
        duration: 10,
      })

      handleNavigateAfterSave()
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to save the invoice')
    } finally {
      setSubmitting(false)
    }
  }

  const handleAutoMatch = async () => {
    const showIntegrationModal = maybeShowIntegrationBeforePost()
    const showRejectionModal = maybeShowRejectionModalBeforeReject()

    if (showIntegrationModal || showRejectionModal) {
      return
    }

    try {
      invoiceStore.invoiceSubscribe(invoice.id, userStore.currentUser?.id)
      setSubmitting(true)
      setIsCorrellating(true)
      await saveInvoice(true)
      setSelectedInvoiceDirty(false)
      if (saveAndNextDefault) {
        handleNavigateAfterSave()
      }
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to save the invoice')
    } finally {
      setSubmitting(false)
    }
  }

  const handleDeleteInvoice = async () => {
    drawerRef.current?.show()
  }

  const handleDiscardInvoice = async (discardReason: string, discardExplained?: string) => {
    try {
      await invoiceStore.delete(invoice.id, discardReason, discardExplained)

      if (usingConsolidatedInvoice) {
        message.success('Invoice deleted')

        goBack()

        return
      }

      notification.success({
        message: 'Invoice deleted',
        description: 'Please allow a few seconds for your changes to appear',
        placement: 'bottomLeft',
        duration: 10,
      })

      goBack()
    } catch {
      message.error('Unable to delete this Invoice')
    }
  }

  const handleAttachOrder = (orderId) => {
    setAttachedOrderIds((prev) => [...prev, orderId])
    setShowCorrelateModal(true)
    setSelectedInvoiceDirty(true)
  }

  const handleUnAttachOrder = (orderId) => {
    setAttachedOrderIds((prev) => prev.filter((prevOrderId) => prevOrderId !== orderId))
    invoiceStore.removeOrder(orderId)
    setSelectedInvoiceDirty(true)
  }

  const handleChangeName = (name: string) => {
    invoiceStore.updateSelectedInvoice('name', name)
  }

  const activeStep = useMemo(() => {
    const activeStepParam = new URLSearchParams(location.search).get('step')

    if (activeStepParam) {
      const activeStep = Number(activeStepParam)
      return [0, 1, 2].includes(activeStep) ? activeStep : 0
    }

    return 0
  }, [location.search])

  if (isLoading || isLoadingCompanyMaterialConfiguration || isLoadingCompanySettings) return <Loading />

  if (!invoice && isReprocessing) {
    // auto refresh the page after 5 seconds
    setTimeout(() => window.location.reload(), 5000)
    return <InvoiceGone goBack={goBack} />
  }

  if (!invoice) return null

  const disableUpdate = isCorrelating

  return (
    <Page>
      <UnloadWarning
        showWarning={() => selectedInvoiceDirty}
        onSaveAndLeave={handleSaveInvoiceAndLeave}
        isSubmitting={isSubmitting}
      />

      <Header
        disabled={isInvoiceLocked}
        goBack={goBack}
        isCorrelating={isCorrelating}
        onDeleteInvoice={handleDeleteInvoice}
        onClickReportIssue={() => setOpenIssueModal(true)}
        toggleRejectionReasonModal={toggleRejectionReasonModal}
        disableUpdate={disableUpdate}
        isSubmitting={isSubmitting}
        saveAndNextDefault={saveAndNextDefault}
        onChangeName={handleChangeName}
        onSaveInvoice={handleSaveInvoice}
        onSaveInvoiceAndNext={handleSaveInvoiceAndNext}
        assignToId={assignToId}
        onChangeAssignToId={setAssignToId}
        stateId={stateId}
        onChangeStateId={setStateId}
        attachedOrderIds={attachedOrderIds}
        onAttachOrder={handleAttachOrder}
        onUnAttachOrder={handleUnAttachOrder}
        accountingDateEnabled={accountingDateEnabled}
        saveInvoice={saveInvoice}
        onConfirmSync={handleConfirmSync}
      />

      <WarnNotices
        showWarnNotice={invoice?.duplicated_invoices?.length > 0}
        duplicatedInvoices={invoice?.duplicated_invoices}
      />

      <InvoiceCorrelateModal
        saveAndNextDefault={saveAndNextDefault}
        isCorrelating={isCorrelating}
        open={showCorrelateModal}
        onCancel={() => setShowCorrelateModal(false)}
        onAutoMatch={handleAutoMatch}
      />

      <Modal
        title="Describe the issue here and hit OK"
        open={openIssueModal}
        onCancel={() => setOpenIssueModal(false)}
        okButtonProps={{ onClick: () => handleSendIssue(), disabled: issueMessage.trim().length === 0 }}
      >
        <Input.TextArea
          value={issueMessage}
          onChange={(e) => setIssueMessage(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>

      <Modal
        title="Rejection Reason"
        open={rejectionReasonOpen}
        onCancel={() => toggleRejectionReasonModal(false)}
        okButtonProps={{
          onClick: () => {
            toggleRejectionReasonModal(false)
            if (saveAndNextDefault) {
              handleSaveInvoiceAndNext()
            } else {
              handleSaveInvoice()
            }
          },
        }}
      >
        <TextArea
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>

      {integrationStore.connected && userStore.canUseIntegrations && integrationStore.invoiceSyncEnabled && (
        <IntegrationSync
          onClose={() => toggleIntegrationModal(false)}
          open={integrationModalOpen}
          onFinish={handleConfirmSync}
          onFinishWithoutSync={handleSaveInvoice}
          isSubmitting={isSubmitting}
        />
      )}

      {isCorrelating && <InvoiceCorrelatingOverlay />}

      {/* Desktop */}
      <Visibility.Hidden breakpoint="lg">
        <PageContent overflowY={activeStep === 0 ? 'hidden' : 'auto'} overflowX="auto" display="flex">
          <DndProvider backend={HTML5Backend}>
            <ExtractedStep visible={activeStep === 0} />

            <ReconcileStep
              saveInvoice={saveInvoice}
              visible={activeStep === 1}
              isLoading={isLoadingOrders}
              attachedOrderIds={attachedOrderIds}
              onAttachOrder={handleAttachOrder}
              onUnAttachOrder={handleUnAttachOrder}
            />
            <ReviewStep visible={activeStep === 2} />
          </DndProvider>
        </PageContent>

        <Footer activeStep={activeStep} />
      </Visibility.Hidden>

      {/* Mobile */}
      <Visibility.Show breakpoint="lg">
        <Page.Tabs
          tabBarGutter={24}
          items={[
            {
              label: 'Details',
              key: 'details',
              children: (
                <Box
                  mt={24}
                  mb={100}
                  width="100%"
                  bg="white"
                  p={16}
                  display="flex"
                  flexDirection="column"
                  style={{ gap: 24 }}
                >
                  <Space direction="vertical" size="small">
                    <Typography.Text type="secondary">VENDOR</Typography.Text>
                    <CompanyVendorSelector disabled={isInvoiceLocked} />
                  </Space>
                  <Space direction="vertical" size="small">
                    <Typography.Text type="secondary">PROJECT</Typography.Text>
                    <InvoiceProject disabled={isInvoiceLocked} />
                  </Space>
                  <Space direction="vertical" size="small">
                    <Typography.Text type="secondary">INVOICE NUMBER</Typography.Text>
                    <InvoiceNumber disabled={isInvoiceLocked} />
                  </Space>
                  <Space direction="vertical" size="small">
                    <Typography.Text type="secondary">INVOICE DATE</Typography.Text>
                    <DocumentDate disabled={isInvoiceLocked} />
                  </Space>
                  {accountingDateEnabled && (
                    <Space direction="vertical" size="small">
                      <Typography.Text type="secondary">ACCOUNTING DATE</Typography.Text>
                      <AccountingDate />
                    </Space>
                  )}
                  <Space direction="vertical" size="small">
                    <Typography.Text type="secondary">ATTACHED ORDERS</Typography.Text>
                    <Box>
                      {invoiceStore.selectedOrders?.map(({ order }, index) => (
                        <React.Fragment key={order.id}>
                          <Typography.Link href={`/order/${order.id}`}>{order.order_number}</Typography.Link>
                          {index < invoiceStore.selectedOrders.length - 1 && ', '}
                        </React.Fragment>
                      ))}
                    </Box>
                  </Space>
                  <Space direction="vertical" size="small">
                    <Typography.Text type="secondary">GRAND TOTAL</Typography.Text>
                    <Typography.Text type="secondary">
                      {currencyFormatter(calculatedMobileGrandTotal, 2)}
                    </Typography.Text>
                  </Space>
                </Box>
              ),
            },
            {
              label: 'PDF',
              key: 'pdf',
              children: (
                <Box pt={24} pb={100} width="100%" height="100%">
                  <PdfViewer
                    url={invoice.extracted_files[0]?.url}
                    style={{ height: '100%', minHeight: '100%', borderRadius: 4 }}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Visibility.Show>

      <Visibility.Show breakpoint="md">
        <FloatActions
          isCorrelating={isCorrelating}
          onDeleteInvoice={handleDeleteInvoice}
          onClickReportIssue={() => setOpenIssueModal(true)}
          disableUpdate={disableUpdate || isInvoiceLocked}
          isSubmitting={isSubmitting}
          saveAndNextDefault={saveAndNextDefault}
          onSaveInvoice={handleSaveInvoice}
          onSaveInvoiceAndNext={handleSaveInvoiceAndNext}
        />
      </Visibility.Show>

      <InvoiceDiscardDrawer
        onDiscard={handleDiscardInvoice}
        ref={drawerRef}
        onCancel={() => drawerRef.current?.close()}
      />
    </Page>
  )
})
