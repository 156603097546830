import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import theme from 'common/styles/theme'

export const Total = ({ value = 0, precision = 3 }: { value?: number; precision?: number }) => {
  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      backgroundColor={theme.colors['white']}
      display="flex"
      justifyContent="flex-end"
      style={{ gap: 6 }}
      px={12}
      py={{ _: 10, md: 16 }}
      width="100%"
      boxShadow={`0px -2px 8px 0px ${theme.colors['black']}26`}
    >
      <Typography.Text color={theme.colors['gray-12']}>Grand total</Typography.Text>
      <Typography.Text color={theme.colors['primary-6']} strong>
        {currencyFormatter(value, precision)}
      </Typography.Text>
    </Box>
  )
}
