import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { usePsqlTable } from 'common/components/PsqlTable/psql_table_provider'
import {
  formatDateStringShort,
  formatEnumValue,
  formatForCSVExport,
  currencyFormatter,
} from 'common/helpers/formatters'

import { DownloadButton } from 'contractor/components/DownloadButton'
import { ConsolidatedOrdersMaterials } from 'contractor/server/order_materials'

interface DownloadProps {
  companyAttributes?: string[]
  externalVendorIdEnabled?: boolean
  onLoadData: () => Promise<Array<ConsolidatedOrdersMaterials.OrderMaterial>>
}

const companyAttributeToConsolidatedMapper = new Map([
  ['description', 'company_material_description'],
  ['product_identifier', 'company_material_product_identifier'],
  ['cost_code_id', 'cost_code_code'],
  ['unit', 'company_material_unit_name'],
  ['material', 'company_material_material'],
  ['group', 'company_material_group'],
  ['sub_group', 'company_material_sub_group'],
  ['preferred_vendor_prices', 'company_material_preferred_vendor_prices'],
  ['project_ids', 'project_identifier'],
  ['cost_code_phase_id', 'cost_code_phase_code'],
  ['manufacturer', 'company_material_manufacturer'],
  ['size', 'company_material_size'],
  ['connection_type', 'company_material_connection_type'],
  ['cost_code_phase_id', 'cost_code_phase_code'],
])

export function OrderMaterialsDownload(props: DownloadProps) {
  const { state } = usePsqlTable()
  const { companyAttributes = [], onLoadData } = props

  const headers = [
    { label: 'Project Name', key: 'project_name' },
    { label: 'Project ID', key: 'project_identifier', groupedKey: 'project_name' },
    { label: 'Order Number', key: 'order_number' },
    { label: 'Order Name', key: 'order_name', groupedKey: 'order_number' },
    { label: 'Created At', key: 'created_at' },
    { label: 'Vendor', key: 'vendor_name' },
    { label: 'Status', key: 'order_state' },
    { label: 'Sub Status', key: 'order_sub_state', groupedKey: 'order_state' },
    { label: 'Vendor Note', key: 'vendor_note' },
    { label: 'Company Note', key: 'company_note' },
    { label: 'Vendor Response', key: 'vendor_response', groupedKey: 'vendor_name' },
  ]

  companyAttributes
    .filter((column) => !['project_ids'].includes(column))
    .forEach((column) => {
      if (column === 'cost_code_id') {
        headers.push({ label: 'Cost Code', key: 'cost_code.code' })
      } else {
        headers.push({ label: formatEnumValue(column), key: `company_material.${column}` })
      }
    })

  headers.push(
    { label: 'Quantity', key: 'quantity' },
    { label: 'Quantity Delivered', key: 'quantity_delivered' },
    { label: 'Unit Cost', key: 'unit_cost' },
    { label: 'Extended Cost', key: 'ext_cost' },
  )

  function dataMapper(orderMaterial: ConsolidatedOrdersMaterials.OrderMaterial) {
    const value = {
      project_name: orderMaterial.project_name,
      project_identifier: orderMaterial.project_identifier,
      order_number: orderMaterial.order_number,
      order_name: orderMaterial.order_name,
      created_at: formatDateStringShort(orderMaterial.created_at),
      vendor_name: orderMaterial.company_vendor_name,
      order_state: orderMaterial.order_state,
      order_sub_state: orderMaterial.order_sub_state,
      vendor_note: orderMaterial.vendor_note,
      company_note: orderMaterial.company_note,
      vendor_response: orderMaterial.vendor_response,
      description: orderMaterial.company_material_description,
      product_identifier: orderMaterial.company_material_product_identifier,
    }

    companyAttributes.forEach((column) => {
      switch (column) {
        case 'cost_code_id':
          value['cost_code.code'] = orderMaterial.cost_code_code
          break
        case 'preferred_vendor_prices':
          value['company_material.preferred_vendor_prices'] = orderMaterial.company_material_preferred_vendor_prices
            ?.map(({ vendor_name, price }) => `${vendor_name}: ${currencyFormatter(price)}`)
            .join(', ')

          break
        case 'unit':
          if (orderMaterial.company_material_unit_id) {
            value['company_material.unit'] = orderMaterial.company_material_unit_name_with_increment_label
            break
          }

          if (orderMaterial.company_material_unit_name) {
            value['company_material.unit'] = orderMaterial.company_material_unit_name
            break
          }

          value['company_material.unit'] = ''

          break
        default:
          value[`company_material.${column}`] = orderMaterial[companyAttributeToConsolidatedMapper.get(column)]
      }
    })

    value['quantity'] = orderMaterial.quantity
    value['quantity_delivered'] = orderMaterial.quantity_delivered
    value['unit_cost'] = orderMaterial.unit_cost
    value['ext_cost'] = orderMaterial.ext_cost

    Object.keys(value).forEach((key) => {
      if (value[key] === undefined) {
        value[key] = ''
      }

      value[key] = formatForCSVExport(value[key])
    })

    return value
  }

  const headersFiltered = headers.filter(
    (header) => !state.hiddenColumns.includes(header.key) && !state.hiddenColumns.includes(header.groupedKey),
  )

  return (
    <Box p="12">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography.Title level={5}>Download</Typography.Title>
        <DownloadButton
          dataMapper={dataMapper}
          title="Order Materials"
          filename="order_materials"
          headers={headersFiltered}
          onLoadData={onLoadData}
        />
      </Box>
    </Box>
  )
}
