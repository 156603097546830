import React from 'react'

import { Alert, Space, Table as AntdTable } from 'antd'
import { TableProps as AntdTableProps } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import {
  ActionsColumn,
  MaterialColumn,
  QuantityColumn,
  UnitColumn,
  UnitCostColumn,
  VendorResponseColumn,
  TaxColumn,
  TaxTitle,
} from 'common/components/OrderMaterialsV2/Columns'
import { DataSource, OrderMaterialsProps } from 'common/components/OrderMaterialsV2/order_materials'
import { DeliveryIssues } from 'common/components/OrderMaterialsV2/Table/delivery_issues'
import { RequiredMark } from 'common/components/OrderMaterialsV2/Table/required_mark'
import { makeCostCodeValue, SelectCostCode } from 'common/components/SelectCostCode'
import { SelectPhaseCode } from 'common/components/SelectPhaseCode'
import { calcExtCost } from 'common/helpers/order'
import { OrderMaterial } from 'common/server/orders'
import { VendorResponseType } from 'common/server/server_types'
import theme from 'common/styles/theme'

import { MaterialsAutocomplete, MaterialsAutocompleteProps } from 'contractor/components/MaterialsAutocomplete'

import { Wrapper } from './wrapper'

type TableProps = Pick<
  OrderMaterialsProps,
  | 'dataSource'
  | 'hideAddNewItem'
  | 'disabled'
  | 'canEditMaterialDatabase'
  | 'canViewHistoricalPricing'
  | 'editCompanyNote'
  | 'orderType'
  | 'requiredFields'
  | 'quantityInput'
  | 'unitInput'
  | 'taxInput'
  | 'hideTax'
  | 'hideUnitCost'
  | 'showDefaultVendor'
  | 'showDefaultVendorAlert'
  | 'showMaterialPriceDetails'
  | 'unitCostInput'
  | 'costCodeInput'
  | 'hideCostCode'
  | 'phaseCodeInput'
  | 'costCodeSettings'
  | 'hideVendorResponse'
  | 'vendorResponseInput'
  | 'isSelecting'
  | 'companyVendorId'
  | 'orderState'
  | 'commitmentId'
  | 'isRequest'
> & {
  isReplacingMaterial?: { [key: number]: boolean }
  isMaterialRequester?: boolean
  showDeliveryIssues?: boolean
  showDeleteItem?: boolean
  overBudgetMaterial?: string
  isDraft?: boolean
  roundingPrecision?: number
  materialsAutocompleteProps: MaterialsAutocompleteProps
  onApproveCompanyMaterial: (companyMaterial: DataSource['company_material']) => void
  onEditCompanyMaterial: (companyMaterial: DataSource['company_material']) => void
  onReplaceCompanyMaterial: (
    newCompanyMaterial: DataSource['company_material'],
    prevCompanyMaterialIndex: number,
  ) => void
  onToggleReplaceCompanyMaterial: (companyMaterialIndex: number) => void
  onTogglePriceHistoryCompanyMaterial: (companyMaterial: DataSource['company_material']) => void
  onRemoveMaterial: (index: number) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDataWithIndex: (index: number, field: string, value: any, shouldApplyToAll?: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeDataUnitWithIndex: (index: number, value: any) => void
  onChangeVendorResponse: (data: { index: number; value: string; row: DataSource }) => void

  rowSelection: {
    selectedRowKeys: React.Key[]
    onChange: (newSelectedRowKeys: React.Key[]) => void
  }
}

export const Table = observer<TableProps>((props) => {
  const {
    dataSource,
    disabled,
    canEditMaterialDatabase,
    canViewHistoricalPricing,
    editCompanyNote,
    orderType,
    requiredFields,
    costCodeSettings,
    materialsAutocompleteProps,
    isSelecting,
    companyVendorId,
    rowSelection,
    orderState,
    isReplacingMaterial,
    roundingPrecision,
    commitmentId,
    overBudgetMaterial,
    isRequest,
  } = props

  const { showDefaultVendor, showDefaultVendorAlert, showMaterialPriceDetails } = props

  const { quantityInput, unitInput, unitCostInput, costCodeInput, phaseCodeInput, taxInput, vendorResponseInput } =
    props

  const { hideAddNewItem, hideUnitCost, hideCostCode, hideVendorResponse, hideTax } = props

  const showCommitmentQuantity = !!commitmentId

  const isRequiredField = (field: string) => {
    if (isRequest) {
      return requiredFields?.request?.[field]
    }

    if (orderType === 'Order') {
      return requiredFields?.order?.[field]
    }

    return requiredFields?.quote?.[field]
  }

  const {
    onReplaceCompanyMaterial,
    onToggleReplaceCompanyMaterial,
    onTogglePriceHistoryCompanyMaterial,
    onEditCompanyMaterial,
    onRemoveMaterial,
    onChangeDataWithIndex,
    onChangeDataUnitWithIndex,
    onChangeVendorResponse,
    onApproveCompanyMaterial,
    showDeleteItem,
  } = props

  return (
    <FlexBoxY
      width="100%"
      maxHeight="calc(100% - 36px)"
      minHeight={{ _: 200, lg: 175 }}
      boxShadow={`0 4px 6px -4px ${theme.colors['black']}26`}
    >
      <Wrapper alignItems="flex-start" justifyContent="flex-start" width="100%" overflowX="auto">
        {!!overBudgetMaterial && (
          <Alert
            style={{ marginBottom: 8, width: '100%' }}
            message={'The materials highlighted surpass the commitment limit'}
            type="warning"
            showIcon
          />
        )}
        <AntdTable<DataSource>
          rowKey={(record) => record?.internal_id}
          pagination={false}
          size="small"
          tableLayout="auto"
          style={{ width: '100%' }}
          scroll={{ y: 'max-content' }}
          data-cy="material-table"
          aria-label="order-materials-table"
          dataSource={dataSource as DataSource[]}
          rowSelection={isSelecting ? rowSelection : undefined}
          rowClassName={(record) => {
            if (record?.company_material?.['requested_by_id'] && !!orderState) {
              return 'requested_material'
            }

            if (record?.company_material?.id === overBudgetMaterial) {
              return 'requested_material'
            }

            return ''
          }}
          columns={[
            ...(hideAddNewItem
              ? []
              : ([
                  {
                    width: 36,
                    render: (_, record, index) => {
                      return (
                        <ActionsColumn
                          disabled={disabled}
                          onApprove={() => onApproveCompanyMaterial(record?.company_material)}
                          onEdit={() => onEditCompanyMaterial(record?.company_material)}
                          onToggleReplace={() => onToggleReplaceCompanyMaterial(index)}
                          onTogglePriceHistory={() => onTogglePriceHistoryCompanyMaterial(record?.company_material)}
                          onRemove={() => onRemoveMaterial(index)}
                          canEditMaterialDatabase={canEditMaterialDatabase}
                          canViewHistoricalPricing={canViewHistoricalPricing}
                          showApprove={record?.company_material?.['requested_at']}
                          isReplacingMaterial={isReplacingMaterial[index]}
                          showDelete={showDeleteItem}
                        />
                      )
                    },
                  },
                ] as AntdTableProps<DataSource>['columns'])),
            {
              title: 'Item',
              dataIndex: ['company_material', 'description'],
              render: (description, record, index) => {
                if (isReplacingMaterial[index]) {
                  return (
                    <MaterialsAutocomplete
                      {...materialsAutocompleteProps}
                      onSelect={(newCompanyMaterial) =>
                        onReplaceCompanyMaterial(newCompanyMaterial as unknown as DataSource['company_material'], index)
                      }
                      placeholder={`Replacing "${description}"`}
                    />
                  )
                }

                const item = record?.['company_material']
                return (
                  <MaterialColumn
                    description={description}
                    disabled={disabled}
                    item={item}
                    companyNote={record?.company_note}
                    deliveryIssue={record?.has_open_issue}
                    onChangCompanyNote={(value) => {
                      if (!editCompanyNote) return

                      onChangeDataWithIndex(index, 'company_note', value)
                    }}
                  />
                )
              },
            },
            {
              title: <RequiredMark title="Qty" isRequired={isRequiredField('quantity')} />,
              dataIndex: 'quantity',
              width: 130,
              render: (quantity, record, index) => {
                const remainingQuantity = Number(record.remaining_quantity)
                return (
                  <FlexBoxY>
                    <QuantityColumn
                      value={quantity}
                      remainingQuantity={showCommitmentQuantity && record?.remaining_quantity}
                      onChange={(value) => onChangeDataWithIndex(index, 'quantity', value)}
                      disabled={disabled}
                      prevValue={record?.prev_quantity}
                      required={isRequiredField('quantity')}
                      {...quantityInput}
                      edit={record?.vendor_response?.includes(VendorResponseType.CHANGED_UNITS) || quantityInput?.edit}
                    />
                    {showCommitmentQuantity && <FlexBoxX width="100%">Remaining: {remainingQuantity}</FlexBoxX>}
                    {record?.has_open_issue && (
                      <FlexBoxX width="100%">
                        Delivered: {record.quantity_delivered}
                        <DeliveryIssues orderMaterial={record as unknown as OrderMaterial} />
                      </FlexBoxX>
                    )}
                  </FlexBoxY>
                )
              },
            },
            {
              title: <RequiredMark title="Unit" isRequired={isRequiredField('unit')} />,
              dataIndex: 'unit',
              width: 96,
              render: (unit, record, index) => (
                <Box minWidth="80px">
                  <UnitColumn
                    value={unit}
                    onChange={(_, value) => onChangeDataUnitWithIndex(index, value)}
                    prevValue={record?.prev_unit?.label}
                    {...unitInput}
                    disabled={disabled || unitInput?.disabled || !!commitmentId}
                    edit={record?.vendor_response?.includes(VendorResponseType.CHANGED_UNITS) || unitInput?.edit}
                  />
                </Box>
              ),
            },
            ...(hideUnitCost
              ? []
              : ([
                  {
                    title: <RequiredMark title="Unit Cost" isRequired={isRequiredField('unit_cost')} />,
                    dataIndex: 'unit_cost',
                    width: 150,
                    render: (unitCost, record, index) => {
                      const extCost = calcExtCost({
                        unitCost,
                        quantity: record?.quantity,
                        multiplier: Number(record?.unit?.original?.multiplier),
                        qtyIncrement: Number(record?.unit?.original?.qty_increment),
                      })

                      const preferredVendorPrices =
                        record?.company_material['company_material_vendor_prices'] ||
                        record?.company_material['preferred_vendor_prices']
                      return (
                        <UnitCostColumn
                          extCost={extCost}
                          disabled={!!commitmentId || disabled || !!record?.company_material['unit_price']}
                          unitCost={unitCost}
                          isCommitment={!!commitmentId}
                          roundingPrecision={roundingPrecision}
                          defaultVendors={showDefaultVendor ? preferredVendorPrices : []}
                          showDefaultVendorAlert={showDefaultVendorAlert}
                          showMaterialPriceDetails={showMaterialPriceDetails}
                          companyMaterialId={
                            record?.company_material['is_catalog'] ? null : record?.company_material?.id
                          }
                          companyVendorId={companyVendorId}
                          companyMaterialDescription={record.company_material.description}
                          {...unitCostInput}
                          onChange={(value, params) => {
                            const { defaultVendor } = params || {}
                            onChangeDataWithIndex(index, 'unit_cost', value)
                            onChangeDataWithIndex(index, 'preferred_vendor_price', defaultVendor)
                            unitCostInput?.onChange?.(value, { defaultVendor, index })
                          }}
                          onChangeDefaultVendorSelected={(defaultVendor) =>
                            onChangeDataWithIndex(index, 'preferred_vendor_price', defaultVendor)
                          }
                          defaultVendorSelected={record['preferred_vendor_price']}
                        />
                      )
                    },
                  },
                ] as AntdTableProps<DataSource>['columns'])),
            ...(hideTax
              ? []
              : [
                  {
                    title: <TaxTitle isRequired={isRequiredField('tax_value')} />,
                    dataIndex: 'tax_value',
                    width: 150,
                    render: (taxValue, record, index) => {
                      const extCost = calcExtCost({
                        unitCost: record.unit_cost,
                        quantity: record?.quantity,
                        multiplier: record?.unit?.original?.multiplier,
                        qtyIncrement: record?.unit?.original?.qty_increment,
                      })

                      return (
                        <Box minWidth="124px">
                          <TaxColumn
                            value={taxValue}
                            onChange={(value) => onChangeDataWithIndex(index, 'tax_value', value)}
                            {...taxInput}
                            extCost={extCost}
                          />
                        </Box>
                      )
                    },
                  },
                ]),
            ...(hideCostCode
              ? []
              : ([
                  {
                    title: (
                      <RequiredMark
                        title="Cost Code"
                        isRequired={isRequiredField('cost_code') || isRequiredField('cost_code_phase_id')}
                      />
                    ),
                    dataIndex: 'cost_code_id',
                    width: 216,
                    render: (_, record, index) => (
                      <Space direction="vertical">
                        <SelectCostCode
                          value={makeCostCodeValue(record['cost_code'], costCodeInput?.costCodeSettings)}
                          onChange={(option, shouldPropagateValue = false) =>
                            onChangeDataWithIndex(index, 'cost_code', option?.costCode, shouldPropagateValue)
                          }
                          disabled={disabled}
                          isCommitment={!!commitmentId}
                          style={{ minWidth: 200 }}
                          {...costCodeInput}
                          defaultApplyToAllChecked={record.default_cost_code_to_be_applied}
                          onChangeApplyToAll={(props) =>
                            costCodeInput.onChangeApplyToAll({
                              ...props,
                              index,
                            })
                          }
                        />
                        {costCodeSettings?.independent_phase_codes_enabled && (
                          <SelectPhaseCode
                            style={{ minWidth: 200 }}
                            value={record?.cost_code_phase_id}
                            onChange={(value = null, shouldProppagateValue = false) => {
                              onChangeDataWithIndex(index, 'cost_code_phase_id', value, shouldProppagateValue)
                            }}
                            disabled={disabled}
                            isCommitment={!!commitmentId}
                            {...phaseCodeInput}
                            defaultApplyToAllChecked={record.default_cost_code_to_be_applied}
                            onChangeApplyToAll={(props) => {
                              phaseCodeInput.onChangeApplyToAll({
                                ...props,
                                index,
                              })
                            }}
                          />
                        )}
                      </Space>
                    ),
                  },
                ] as AntdTableProps<DataSource>['columns'])),
            ...(hideVendorResponse
              ? []
              : ([
                  {
                    title: 'Vendor Response',
                    dataIndex: 'vendor_note',
                    width: vendorResponseInput?.edit ? 280 : 500,
                    render: (vendorNote, record, index) => (
                      <Box style={{ minWidth: 140 }}>
                        <VendorResponseColumn
                          inputNoteProps={{
                            value: vendorNote,
                            onChange: ({ target }) => onChangeDataWithIndex(index, 'vendor_note', target.value),
                            disabled,
                            ...vendorResponseInput?.inputNoteProps,
                          }}
                          inputUnitCostProps={{
                            value: record?.changed_unit_cost_by_vendor,
                            onChange: (value) => onChangeDataWithIndex(index, 'changed_unit_cost_by_vendor', value),
                            disabled,
                            ...vendorResponseInput?.inputUnitCostProps,
                          }}
                          selectProps={{
                            value: record?.vendor_response || [],
                            onChange: (value = []) => onChangeVendorResponse({ index, value, row: record }),
                            disabled,
                            ...vendorResponseInput?.selectProps,
                          }}
                          {...vendorResponseInput}
                        />
                      </Box>
                    ),
                  },
                ] as AntdTableProps<DataSource>['columns'])),
          ]}
          summary={() => {
            return (
              <AntdTable.Summary fixed="top">
                <AntdTable.Summary.Row>
                  <AntdTable.Summary.Cell colSpan={isSelecting ? 3 : 2} index={0}>
                    <Box ml={36}>
                      <MaterialsAutocomplete {...materialsAutocompleteProps} />
                    </Box>
                  </AntdTable.Summary.Cell>
                  <AntdTable.Summary.Cell index={2}>
                    <QuantityColumn {...quantityInput} disabled />
                  </AntdTable.Summary.Cell>
                  <AntdTable.Summary.Cell index={1}>
                    <Box minWidth="80px">
                      <UnitColumn {...unitInput} disabled />
                    </Box>
                  </AntdTable.Summary.Cell>
                  {!hideUnitCost && (
                    <AntdTable.Summary.Cell index={3}>
                      <UnitCostColumn {...unitCostInput} disabled showMaterialPriceDetails={showMaterialPriceDetails} />
                    </AntdTable.Summary.Cell>
                  )}
                  {!hideTax && (
                    <AntdTable.Summary.Cell index={4}>
                      <UnitCostColumn {...taxInput} disabled />
                    </AntdTable.Summary.Cell>
                  )}
                  {!hideCostCode && (
                    <AntdTable.Summary.Cell index={5}>
                      <SelectCostCode {...costCodeInput} disabled placeholder="" style={{ minWidth: 200 }} />
                    </AntdTable.Summary.Cell>
                  )}
                  {!hideVendorResponse && (
                    <AntdTable.Summary.Cell index={6}>
                      <VendorResponseColumn selectProps={{ disabled: true }} />
                    </AntdTable.Summary.Cell>
                  )}
                </AntdTable.Summary.Row>
              </AntdTable.Summary>
            )
          }}
        />
      </Wrapper>
    </FlexBoxY>
  )
})
