import React from 'react'

import { Alert, Button, InputRef, Modal } from 'antd'

import { Box } from 'common/components/boxes'
import { TextArea } from 'common/components/TextArea'
import { InvoiceInboxFilesStates, InvoiceInboxSubStates } from 'common/server/server_types'

import { ConsolidatedInvoiceInboxes } from 'contractor/server/invoices/inbox/consolidated_invoice_inboxes'

import { IgnoredPdfDetails } from './ignored_pdf_details'

interface Props {
  invoiceInbox: ConsolidatedInvoiceInboxes.InvoiceInbox
  onSendIssue: (text: string) => Promise<void>
  onSendTip: (text: string) => Promise<void>
}

export function InvoiceInboxDetailsDrawerWarnAlert(props: Props) {
  const { invoiceInbox, onSendIssue, onSendTip } = props

  const inputRef = React.useRef<InputRef>(null)
  const [openModal, setOpenModal] = React.useState<'tip' | 'issue' | undefined>()
  const [text, setText] = React.useState('')

  const message = invoiceInbox.files.some((file) => file.state === InvoiceInboxFilesStates.SUCCESS)
    ? 'Some files has been auto marked as Ignored'
    : 'This inbox has been auto marked as Ignored'

  const someFilesAreIgnored = invoiceInbox.files.some((file) => {
    return file.state === InvoiceInboxFilesStates.AUTO_IGNORED || invoiceInbox.files.length === 0
  })

  if (invoiceInbox?.sub_state !== InvoiceInboxSubStates.AUTO_IGNORED && !someFilesAreIgnored) {
    return null
  }

  function handleOpenModal(modal: 'tip' | 'issue') {
    setOpenModal(modal)

    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }

  function handleCloseModal() {
    setOpenModal(undefined)
    setText('')
  }

  async function handleSend() {
    if (openModal === 'issue') {
      await onSendIssue(text)
      handleCloseModal()

      return
    }

    await onSendTip(text)
    handleCloseModal()
  }

  return (
    <>
      <Modal
        title={
          openModal === 'issue'
            ? 'Describe the issue here and hit OK'
            : 'How can we improve? Describe it here and hit OK'
        }
        open={!!openModal}
        onCancel={handleCloseModal}
        okButtonProps={{
          onClick: handleSend,
          disabled: text.length === 0,
        }}
      >
        <TextArea
          ref={inputRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
          showCount
          maxLength={255}
          style={{ height: 120, resize: 'none' }}
        />
      </Modal>

      <Alert
        showIcon
        message={message}
        type="warning"
        description={
          <Box>
            <Box mb={24}>
              <IgnoredPdfDetails files={invoiceInbox?.files || []} />
            </Box>

            <Box display="flex" justifyContent="flex-end" gridGap={8}>
              <Button onClick={() => handleOpenModal('issue')}>Something is wrong?</Button>
              <Button onClick={() => handleOpenModal('tip')}>Help us improve</Button>
            </Box>
          </Box>
        }
      />
    </>
  )
}
